import api from '../../services/api';

export function sendPasswordResetRequest(parms) {
    const data = api
        .post(
            `/account/password_reset_request`,
            { ...parms },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function sendPasswordReset(token, parms) {
    const data = api
        .post(
            `/account/password_reset/${token}`,
            { ...parms },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchCompany() {
    const data = api
        .get('/account/settings', { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function updateSettings(parms) {
    const data = api
        .put('/account/settings', { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchPlans(planType = 'sleep_diary') {
    const data = api
        .get('/account/plans', {
            params: {
                type: planType,
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchPaymentMethod() {
    const data = api
        .get('/account/payment_method', { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function updatePaymentMethod(parms) {
    const data = api
        .put('/account/payment_method', { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchSubscriptionData() {
    const data = api
        .get('/account/my_account', { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function createStripeSetupIntent() {
    const data = api
        .post('/account/stripe/setup_intent', {}, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchStripePaymentIntent() {
    const data = api
        .get('/account/stripe/payment_intent', { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function cancelSubscription(parms) {
    const data = api
        .put('/subscriptions/cancel', { ...parms }, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchCompanySubscription(company_id) {
    const data = api
        .get(`/account/${company_id}/subscription`, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function addSubscriptionDiscount(company_id, parms) {
    const data = api
        .post(
            `/account/${company_id}/subscription`,
            { ...parms },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function updateSubscriptionDiscount(company_id, parms) {
    const data = api
        .put(
            `/account/${company_id}/subscription`,
            { ...parms },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function deleteSubscriptionDiscount(company_id) {
    const data = api
        .delete(`/account/${company_id}/subscription`, {
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function fetchTrialPlan(planType) {
    const data = api
        .get(`/subscriptions/trial/${planType}`, { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function createNewTrialSubscription(planId, locale) {
    const data = api
        .post(
            '/subscriptions/trial',
            {
                plan_id: planId,
                locale,
            },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function payPendingInvoice(params) {
    const data = api
        .post(
            '/subscriptions/pay_pending_invoice',
            {
                ...params,
            },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function createNationalSubscription(params) {
    const data = api
        .post(
            '/subscriptions/national',
            {
                ...params,
            },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function createInternationalSubscription(params) {
    const data = api
        .post(
            '/subscriptions/international',
            {
                ...params,
            },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function updateInternationalSubscription(params) {
    const data = api
        .put(
            '/subscriptions/international',
            {
                ...params,
            },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function activateSubscription(subscriptionId, planId, active) {
    const data = api
        .patch(
            `/activate_subscription/${subscriptionId}`,
            {
                plan_id: planId,
                active,
            },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function getStripeFutureInvoiceValue(planId, numberOfDevices = null) {
    const data = api
        .get('/account/stripe/future_invoice', {
            params: {
                plan_id: planId,
                number_of_devices: numberOfDevices,
            },
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function reactivateTrialSubscription(id, params) {
    const data = api
        .put(`/subscriptions/${id}/reactivate_trial`, params, {
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function extendTrialSubscription(id, params) {
    const data = api
        .put(`/subscriptions/${id}/extend_trial`, params, {
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function extendPaidSubscription(id, params) {
    const data = api
        .put(`/subscriptions/${id}/extend_paid`, params, {
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function changePlanSubscription(id, params) {
    const data = api
        .put(`/subscriptions/${id}/change_plan`, params, {
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function addPlanToSubscription(id, params) {
    const data = api
        .put(`/subscriptions/${id}/add_plan`, params, {
            withCredentials: true,
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function get2faRecoveryCodes() {
    const data = api
        .get('/account/2fa/setup', { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function setup2fa(recoveryCodes) {
    const data = api
        .post(
            '/account/2fa/setup',
            {
                recovery_codes: recoveryCodes,
            },
            { withCredentials: true }
        )
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}

export function disable2fa() {
    const data = api
        .delete('/account/2fa/setup', { withCredentials: true })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log('error', error);
        });
    return data;
}
