export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            companyText: 'Empresa',
            nameText: 'Nome',
            responsibleText: 'Responsável',
            emailText: 'e-Mail',
            subscriptionText: 'Assinatura e cobrança',
            totalPriceText: 'Preço total',
            billingDateText: 'Próxima data de cobrança',
            suspendedReasonText: 'Motivo da suspensão',
            subscriptionPlansText: 'Planos na assinatura',
            planText: 'Plano',
            typeText: 'Tipo',
            priceText: 'Preço',
            expiryDateText: 'Data de expiração',
            actionText: 'Ação',
            daysToExtendText: 'Dias para estender',
            newExpiryDateText: 'Nova data de expiração',
            couponsText: 'Cupons aplicados',
            couponNameText: 'Nome do cupom',
            expiryMonthText: 'Mês em que expira',
            expiryYearText: 'Ano em que expira',
            addCouponText: 'Adicionar cupom',
            deleteCouponText: 'Excluir cupom',
            applyCouponSuccessText: 'Cupom aplicado com sucesso!',
            updateCouponSuccessText: 'Cupom atualizado com sucesso!',
            removeCouponSuccessText: 'Cupom removido com sucesso!',
            saveText: 'Salvar',
            cancelText: 'Cancelar',
            yesText: 'Sim',
            noText: 'Não',
            successText: 'Mudanças aplicadas com sucesso!',
            monthlyPaymentFrequencyText: '/mês',
            unlimitedActivePatientsText: 'Pacientes ativos ilimitados',
            activePatientsText: 'pacientes ativos',
            unlimitedActiveDevicesText: 'Dispositivos ativos ilimitados',
            activeDevicesText: 'dispositivos ativos',
            expiresAtText: 'Expira em',
            activeText: 'Ativa',
            suspendedText: 'Suspensa',
            expiredText: 'Expirada',
            attentionText: 'Atenção',
            closeText: 'Fechar',
            unsubscribeText: 'Cancelar assinatura',
            goBackText: 'Voltar',
            trialExpiredReasonText: 'Assinatura trial finalizada.',
            invalidPaymentMethodReasonText: 'Falha com o método de pagamento.',
            invoiceExpiredReasonText: 'Fatura expirada.',
            sleepDiarySubscriptionText: 'Assinatura do Diário de Sono',
            lumusSubscriptionText: 'Assinatura do Lumus',
            addPlanText: 'Adicionar plano',
            removePlanText: 'Remover plano',
            numberOfPatientsText: 'Número de pacientes',
            numberOfPaidMonthsText: 'Número de meses pagos',
            addText: 'Adicionar',
            numberOfDevicesText: 'Número de dispositivos',
            editText: 'Editar',
            extendTrialText: 'Estender trial',
            activateTrialText: 'Ativar trial',
            activateText: 'Ativar',
            suspendText: 'Suspender',
            extendPaidText: 'Estender paga',
            changePlanText: 'Mudar plano',
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            companyText: 'Comapny',
            nameText: 'Name',
            responsibleText: 'Manager',
            emailText: 'e-Mail',
            subscriptionText: 'Subscription and billing',
            totalPriceText: 'Total price',
            billingDateText: 'Next billing date',
            suspendedReasonText: 'Suspended reason',
            subscriptionPlansText: 'Plans in subscription',
            planText: 'Plan',
            typeText: 'Type',
            priceText: 'Price',
            expiryDateText: 'Expiry date',
            actionText: 'Action',
            daysToExtendText: 'Days to extend',
            newExpiryDateText: 'New expiration date',
            couponsText: 'Applied coupons',
            couponNameText: 'Coupon name',
            expiryMonthText: 'Month in which it expires',
            expiryYearText: 'Year in which it expires',
            addCouponText: 'Add coupon',
            deleteCouponText: 'Remove coupon',
            applyCouponSuccessText: 'Coupon succesfully applied!',
            updateCouponSuccessText: 'Coupon succesfully updated!',
            removeCouponSuccessText: 'Coupon succesfully removed!',
            saveText: 'Save',
            cancelText: 'Cancel',
            yesText: 'Yes',
            noText: 'No',
            successText: 'Changes applied successfully!',
            monthlyPaymentFrequencyText: '/mo',
            unlimitedActivePatientsText: 'Unlimited active patients',
            activePatientsText: 'active patients',
            unlimitedActiveDevicesText: 'Unlimited active devices',
            activeDevicesText: 'active devices',
            expiresAtText: 'Expires at',
            activeText: 'Active',
            suspendedText: 'Suspended',
            expiredText: 'Expired',
            attentionText: 'Attention',
            closeText: 'Close',
            unsubscribeText: 'Unsubscribe',
            goBackText: 'Go back',
            trialExpiredReasonText: 'Trial subscription expired.',
            invalidPaymentMethodReasonText: 'Payment method failed.',
            invoiceExpiredReasonText: 'Expired invoice.',
            sleepDiarySubscriptionText: 'Sleep Diary subscription',
            lumusSubscriptionText: 'Lumus subscription',
            addPlanText: 'Add plan',
            removePlanText: 'Remove plan',
            numberOfPatientsText: 'Number of patients',
            numberOfPaidMonthsText: 'Number of months paid',
            addText: 'Add',
            numberOfDevicesText: 'Number of devices',
            editText: 'Edit',
            extendTrialText: 'Extend trial',
            activateTrialText: 'Activate trial',
            activateText: 'Activate',
            suspendText: 'Suspend',
            extendPaidText: 'Extend paid',
            changePlanText: 'Change plan',
        },
    },
};
