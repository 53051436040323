import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShepherdTour } from 'react-shepherd';
import { ptBR, enUS } from 'date-fns/locale';
import axios from 'axios';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import { stepsOptions, tourOptions } from './tour.config';
import history from '../../services/history';
import api from '../../services/api';
import { fetchCountry } from '../../services/api/ip';
import { fetchDashboardData } from '../../services/api/dashboard';
import { getPublicIp } from '../../helpers/getPublicIp';
import { getArraysOfLastNDays } from '../../helpers/customerDashboard';
import { setLocale } from '../../store/actions/localeActions';
import { setLoading } from '../../store/actions/loadingActions';
import { CondorDashboard } from './components/condorDashboard';
import { CustomerDashboard } from './components/customerDashboard';

const renderComponent = {
    condor_admin: CondorDashboard,
    manager: CustomerDashboard,
    customer_admin: CustomerDashboard,
};

export default function Dashboard({ currentUser }) {
    const [dashboardData, setDashboardData] = useState(null);
    const [steps, setSteps] = useState([]);
    const [ready, setReady] = useState(false);

    const Component = renderComponent[currentUser.permissions];

    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.loading.isLoading);
    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const fetchData = async () => {
            dispatch(setLoading(true));

            if (!api.defaults.headers?.Locale) {
                const ip = await getPublicIp();

                const response = await fetchCountry(ip);

                if (response) {
                    if (response?.country) {
                        if (response.country === 'Brazil') {
                            dispatch(
                                setLocale({
                                    dateLocale: ptBR,
                                    currencyLocale: 'pt-BR',
                                    currencyFormat: 'BRL',
                                })
                            );
                        } else if (response.country === 'United States') {
                            dispatch(
                                setLocale({
                                    dateLocale: enUS,
                                    currencyLocale: 'en-US',
                                    currencyFormat: 'USD',
                                })
                            );
                        } else {
                            dispatch(
                                setLocale({
                                    dateLocale: ptBR,
                                    currencyLocale: 'en-US',
                                    currencyFormat: 'USD',
                                })
                            );
                        }
                    }
                } else {
                    dispatch(
                        setLocale({
                            dateLocale: ptBR,
                            currencyLocale: 'pt-BR',
                            currencyFormat: 'BRL',
                        })
                    );
                }
            }

            const data = await fetchDashboardData(source);

            let last_ten_days = getArraysOfLastNDays(10);

            if (data) {
                data.last_ten_days = last_ten_days;

                if (currentUser.permissions === 'manager') {
                    const showWelcomeTour = JSON.parse(
                        localStorage.getItem('showWelcomeTour')
                    );
                    const showFirstPatientTour = JSON.parse(
                        localStorage.getItem('showFirstPatientTour')
                    );
                    const showNotFilledYetTour = JSON.parse(
                        localStorage.getItem('showNotFilledYetTour')
                    );

                    if (
                        showWelcomeTour === null &&
                        data?.subordinates.length !== 0
                    ) {
                        localStorage.setItem('showWelcomeTour', 'false');
                        localStorage.setItem(
                            'showAddPatientButtonTour',
                            'false'
                        );
                        localStorage.setItem('showAddPatientTour', 'false');
                        localStorage.setItem('showAppDownloadTour', 'false');
                        localStorage.setItem('showFirstPatientTour', 'false');
                        localStorage.setItem('showNotFilledYetTour', 'false');
                        localStorage.setItem('showReportTour', 'false');
                    } else if (
                        (showWelcomeTour === null ||
                            showWelcomeTour === true) &&
                        data?.subordinates.length === 0
                    ) {
                        localStorage.setItem('showWelcomeTour', 'true');
                        setSteps(stepsOptions[lang].welcome);
                    } else if (data?.subordinates.length !== 0) {
                        let user = data?.subordinates[0];
                        let days = last_ten_days?.date;

                        if (user?.data_input?.length !== 0) {
                            if (
                                days.includes(user?.data_input[0]?.date) &&
                                (showFirstPatientTour === null ||
                                    showFirstPatientTour === true)
                            ) {
                                let tempFirstPatientStep = [
                                    ...stepsOptions[lang].firstPatient,
                                ];
                                let nextButton = {
                                    action() {
                                        localStorage.setItem(
                                            'showFirstPatientTour',
                                            'false'
                                        );
                                        history.push({
                                            pathname: '/users/report',
                                            state: { entity: user },
                                        });
                                        return this.complete();
                                    },
                                    text: pageData.next,
                                };

                                tempFirstPatientStep[
                                    tempFirstPatientStep.length - 1
                                ].buttons.push(nextButton);

                                localStorage.setItem(
                                    'showFirstPatientTour',
                                    'true'
                                );
                                localStorage.setItem(
                                    'showNotFilledYetTour',
                                    'false'
                                );
                                setSteps(tempFirstPatientStep);
                            } else {
                                setSteps([]);
                            }
                        } else {
                            if (
                                showNotFilledYetTour === null ||
                                showNotFilledYetTour === true
                            ) {
                                localStorage.setItem(
                                    'showNotFilledYetTour',
                                    'true'
                                );
                                setSteps(stepsOptions[lang].notFilledYet);
                            } else {
                                setSteps([]);
                            }
                        }
                    }
                }

                setDashboardData(data);
                setReady(true);
            }

            dispatch(setLoading(false));
        };

        fetchData();

        return () => {
            source.cancel();
        };
    }, []);

    if (isLoading || !ready) {
        return <></>;
    }

    return (
        <ShepherdTour steps={steps} tourOptions={tourOptions}>
            <Component
                dashboardData={dashboardData}
                pageData={pageData}
                currentUser={currentUser}
            />
        </ShepherdTour>
    );
}

Dashboard.propTypes = {
    currentUser: PropTypes.object.isRequired,
};
