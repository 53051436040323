import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, FormGroup, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import {
    currencyFormatter,
    localeDateFormatter,
} from '../../../../helpers/formatters';
import { addDays, addMonths } from '../../../../helpers/date';
import { handleDate } from '../../../../helpers/dateFormat';
import { validateForm } from '../../../../helpers/formValidator';
import { getLumusPrice } from '../../../../helpers/getLumusPrice';
import {
    reactivateTrialSubscription,
    extendTrialSubscription,
    activateSubscription,
    extendPaidSubscription,
    changePlanSubscription,
    addPlanToSubscription,
} from '../../../../services/api/account';
import { setLoading } from '../../../../store/actions/loadingActions';
import Input from '../../../../components/inputForm/inputText';
import Select from '../../../../components/inputForm/inputSelect';
import { CouponFields } from './couponFields';

MyForm.propTypes = {
    company: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired,
    setSubscription: PropTypes.func.isRequired,
    couponsFields: PropTypes.array.isRequired,
    schemas: PropTypes.object.isRequired,
    addCouponField: PropTypes.func.isRequired,
    removeCouponField: PropTypes.func.isRequired,
    currency: PropTypes.object.isRequired,
    texts: PropTypes.object.isRequired,
    coupons: PropTypes.array.isRequired,
    actionOptions: PropTypes.object.isRequired,
    sleepDiaryPlans: PropTypes.array.isRequired,
    lumusPlans: PropTypes.array.isRequired,
};

export function MyForm({
    company,
    user,
    subscription,
    setSubscription,
    couponsFields,
    schemas,
    addCouponField,
    removeCouponField,
    currency,
    texts,
    coupons,
    actionOptions,
    sleepDiaryPlans,
    lumusPlans,
}) {
    const sleepDiarySubscription = subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'sleep_diary'
    );
    const lumusSubscription = subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'lumus'
    );

    const formRef = useRef(null);

    const { dateLocale } = useSelector((state) => state.locale);
    const lang = useSelector((state) => state.lang.lang);

    const [chosenAction, setChosenAction] = useState({
        sleep_diary: null,
        lumus: null,
    });

    const [showAddSleepDiaryPlanButton, setShowAddSleepDiaryPlanButton] =
        useState(!sleepDiarySubscription);
    const [showAddLumusPlanButton, setShowAddLumusPlanButton] = useState(
        !lumusSubscription
    );

    const [showSleepDiaryPaidMonthsInput, setShowSleepDiaryPaidMonthsInput] =
        useState(false);
    const [
        showSleepDiaryNewExpiresAtInput,
        setShowSleepDiaryNewExpiresAtInput,
    ] = useState(false);
    const [showLumusPaidMonthsInput, setShowLumusPaidMonthsInput] =
        useState(false);
    const [showLumusNewExpiresAtInput, setShowLumusNewExpiresAtInput] =
        useState(false);
    const [isMaxDevicesInputDisabled, setIsMaxDevicesInputDisabled] =
        useState(false);

    const [
        isSleepDiarySubscriptionAddButtonDisabled,
        setIsSleepDiarySubscriptionAddButtonDisabled,
    ] = useState(true);

    const [
        isSleepDiarySubscriptionSaveButtonDisabled,
        setIsSleepDiarySubscriptionSaveButtonDisabled,
    ] = useState(true);

    const [
        isSleepDiarySubscriptionCancelButtonDisabled,
        setIsSleepDiarySubscriptionCancelButtonDisabled,
    ] = useState(true);

    const [
        isLumusSubscriptionAddButtonDisabled,
        setIsLumusSubscriptionAddButtonDisabled,
    ] = useState(true);

    const [
        isLumusSubscriptionSaveButtonDisabled,
        setIsLumusSubscriptionSaveButtonDisabled,
    ] = useState(true);

    const [
        isLumusSubscriptionCancelButtonDisabled,
        setIsLumusSubscriptionCancelButtonDisabled,
    ] = useState(true);

    const dispatch = useDispatch();

    function handleChangeAction(option, type) {
        setTimeout(function () {
            if (
                option?.value === 'extend_trial' ||
                option?.value === 'activate_trial' ||
                option?.value === 'suspend' ||
                option?.value === 'activate' ||
                option?.value === 'extend_paid' ||
                option?.value === 'change_plan'
            ) {
                if (type === 'sleep_diary') {
                    setIsSleepDiarySubscriptionSaveButtonDisabled(false);
                    setIsSleepDiarySubscriptionCancelButtonDisabled(false);
                } else {
                    setIsLumusSubscriptionSaveButtonDisabled(false);
                    setIsLumusSubscriptionCancelButtonDisabled(false);
                }
            } else {
                if (type === 'sleep_diary') {
                    setIsSleepDiarySubscriptionSaveButtonDisabled(true);
                    setIsSleepDiarySubscriptionCancelButtonDisabled(true);
                } else {
                    setIsLumusSubscriptionSaveButtonDisabled(true);
                    setIsLumusSubscriptionCancelButtonDisabled(true);
                }
            }

            setChosenAction((prevState) => {
                const newState = { ...prevState };

                newState[type] = option?.value;

                return newState;
            });
        }, 100);
    }

    function handleResetSubscriptionPlan(type) {
        setTimeout(function () {
            if (type === 'sleep_diary') {
                formRef.current.setFieldValue(
                    `sleep_diary_subscription.action`,
                    { value: null, label: null }
                );

                setChosenAction((prevState) => {
                    const newState = { ...prevState };

                    newState.sleep_diary = null;

                    return newState;
                });
                setIsSleepDiarySubscriptionSaveButtonDisabled(true);
                setIsSleepDiarySubscriptionCancelButtonDisabled(true);
            }

            if (type === 'lumus') {
                formRef.current.setFieldValue(`lumus_subscription.action`, {
                    value: null,
                    label: null,
                });

                setChosenAction((prevState) => {
                    const newState = { ...prevState };

                    newState.lumus = null;

                    return newState;
                });
                setIsLumusSubscriptionSaveButtonDisabled(true);
                setIsLumusSubscriptionCancelButtonDisabled(true);
            }
        }, 100);
    }

    function handlePlanChange(planId, type) {
        setTimeout(function () {
            if (planId) {
                let foundPlan;

                if (type === 'sleep_diary') {
                    foundPlan = sleepDiaryPlans.find(
                        (plan) => plan.id === planId
                    );

                    setShowSleepDiaryPaidMonthsInput(
                        !foundPlan.name.includes('Trial') &&
                            !foundPlan.name.includes('Unlimited')
                    );

                    setShowSleepDiaryNewExpiresAtInput(
                        !foundPlan.name.includes('Unlimited')
                    );

                    formRef.current.setFieldValue(
                        'sleep_diary_subscription.new_max_patients',
                        foundPlan.max_patients
                    );

                    if (foundPlan.name.includes('Trial')) {
                        if (subscription.locale === 'pt-BR') {
                            formRef.current.setFieldValue(
                                'sleep_diary_subscription.new_price',
                                currencyFormatter(0, 'pt-BR', 'BRL')
                            );
                        } else {
                            formRef.current.setFieldValue(
                                'sleep_diary_subscription.new_price',
                                currencyFormatter(0, 'en-US', 'USD')
                            );
                        }

                        const now = new Date();

                        let days = 30;

                        if (foundPlan.name.includes('60')) {
                            days = 60;
                        }

                        const newExpiresAt = addDays(now, days);

                        const year = newExpiresAt.getFullYear();

                        const month =
                            newExpiresAt.getMonth() + 1 >= 10
                                ? newExpiresAt.getMonth() + 1
                                : `0${newExpiresAt.getMonth() + 1}`;

                        const day =
                            newExpiresAt.getDate() <= 9
                                ? `0${newExpiresAt.getDate()}`
                                : newExpiresAt.getDate();

                        formRef.current.setFieldValue(
                            'sleep_diary_subscription.new_expires_at',
                            `${year}-${month}-${day}`
                        );
                    } else if (foundPlan.name.includes('Unlimited')) {
                        if (subscription.locale === 'pt-BR') {
                            formRef.current.setFieldValue(
                                'sleep_diary_subscription.new_price',
                                currencyFormatter(0, 'pt-BR', 'BRL')
                            );
                        } else {
                            formRef.current.setFieldValue(
                                'sleep_diary_subscription.new_price',
                                currencyFormatter(0, 'en-US', 'USD')
                            );
                        }

                        formRef.current.setFieldValue(
                            'sleep_diary_subscription.new_expires_at',
                            ''
                        );

                        formRef.current.setFieldValue(
                            'sleep_diary_subscription.paid_months',
                            1
                        );
                    } else {
                        if (subscription.locale === 'pt-BR') {
                            formRef.current.setFieldValue(
                                'sleep_diary_subscription.new_price',
                                currencyFormatter(
                                    foundPlan.price_brl,
                                    'pt-BR',
                                    'BRL'
                                )
                            );
                        } else {
                            formRef.current.setFieldValue(
                                'sleep_diary_subscription.new_price',
                                currencyFormatter(
                                    foundPlan.price_usd,
                                    'en-US',
                                    'USD'
                                )
                            );
                        }
                    }
                }

                if (type === 'lumus') {
                    foundPlan = lumusPlans.find((plan) => plan.id === planId);

                    setIsMaxDevicesInputDisabled(
                        foundPlan.name.includes('Trial') ||
                            foundPlan.name.includes('Unlimited')
                    );

                    setShowLumusPaidMonthsInput(
                        !foundPlan.name.includes('Trial') &&
                            !foundPlan.name.includes('Unlimited')
                    );

                    setShowLumusNewExpiresAtInput(
                        !foundPlan.name.includes('Unlimited')
                    );

                    if (foundPlan.name.includes('Trial')) {
                        formRef.current.setFieldValue(
                            'lumus_subscription.new_max_devices',
                            5
                        );

                        if (subscription.locale === 'pt-BR') {
                            formRef.current.setFieldValue(
                                'lumus_subscription.new_price',
                                currencyFormatter(0, 'pt-BR', 'BRL')
                            );
                        } else {
                            formRef.current.setFieldValue(
                                'lumus_subscription.new_price',
                                currencyFormatter(0, 'en-US', 'USD')
                            );
                        }

                        const now = new Date();

                        let days = 30;

                        if (foundPlan.name.includes('60')) {
                            days = 60;
                        }

                        const newExpiresAt = addDays(now, days);

                        const year = newExpiresAt.getFullYear();

                        const month =
                            newExpiresAt.getMonth() + 1 >= 10
                                ? newExpiresAt.getMonth() + 1
                                : `0${newExpiresAt.getMonth() + 1}`;

                        const day =
                            newExpiresAt.getDate() <= 9
                                ? `0${newExpiresAt.getDate()}`
                                : newExpiresAt.getDate();

                        formRef.current.setFieldValue(
                            'lumus_subscription.new_expires_at',
                            `${year}-${month}-${day}`
                        );
                    } else if (foundPlan.name.includes('Unlimited')) {
                        formRef.current.setFieldValue(
                            'lumus_subscription.new_max_devices',
                            1000000
                        );

                        if (subscription.locale === 'pt-BR') {
                            formRef.current.setFieldValue(
                                'lumus_subscription.new_price',
                                currencyFormatter(0, 'pt-BR', 'BRL')
                            );
                        } else {
                            formRef.current.setFieldValue(
                                'lumus_subscription.new_price',
                                currencyFormatter(0, 'en-US', 'USD')
                            );
                        }

                        formRef.current.setFieldValue(
                            'lumus_subscription.new_expires_at',
                            ''
                        );

                        formRef.current.setFieldValue(
                            'lumus_subscription.paid_months',
                            1
                        );
                    } else {
                        const max_devices = formRef.current.getFieldValue(
                            'lumus_subscription.new_max_devices'
                        );

                        if (max_devices) {
                            if (subscription.locale === 'pt-BR') {
                                formRef.current.setFieldValue(
                                    'lumus_subscription.new_price',
                                    currencyFormatter(
                                        getLumusPrice(
                                            foundPlan.prices,
                                            Number(max_devices),
                                            'BRL'
                                        ),
                                        'pt-BR',
                                        'BRL'
                                    )
                                );
                            } else {
                                formRef.current.setFieldValue(
                                    'lumus_subscription.new_price',
                                    currencyFormatter(
                                        getLumusPrice(
                                            foundPlan.prices,
                                            Number(max_devices),
                                            'USD'
                                        ),
                                        'en-US',
                                        'USD'
                                    )
                                );
                            }
                        }
                    }
                }
            }
        }, 100);
    }

    function handleNumberOfDevicesChange(number) {
        if (number) {
            const planId = formRef.current.getFieldValue(
                'lumus_subscription.new_plan_id'
            );

            if (planId) {
                const foundPlan = lumusPlans.find((plan) => plan.id === planId);

                if (subscription.locale === 'pt-BR') {
                    formRef.current.setFieldValue(
                        'lumus_subscription.new_price',
                        currencyFormatter(
                            getLumusPrice(
                                foundPlan.prices,
                                Number(number),
                                'BRL'
                            ),
                            'pt-BR',
                            'BRL'
                        )
                    );
                } else {
                    formRef.current.setFieldValue(
                        'lumus_subscription.new_price',
                        currencyFormatter(
                            getLumusPrice(
                                foundPlan.prices,
                                Number(number),
                                'USD'
                            ),
                            'en-US',
                            'USD'
                        )
                    );
                }
            }
        } else {
            formRef.current.setFieldValue('lumus_subscription.new_price', '');
        }
    }

    function handleNumberOfPaidMonthsChange(number, action, type) {
        if (number) {
            if (type === 'sleep_diary') {
                if (action === 'extend_paid') {
                    if (sleepDiarySubscription.expires_at) {
                        const expiresAt = handleDate(
                            sleepDiarySubscription.expires_at
                        );

                        const newExpiresAt = addMonths(
                            expiresAt,
                            Number(number)
                        );

                        const year = newExpiresAt.getFullYear();

                        const month =
                            newExpiresAt.getMonth() + 1 >= 10
                                ? newExpiresAt.getMonth() + 1
                                : `0${newExpiresAt.getMonth() + 1}`;

                        const day =
                            newExpiresAt.getDate() <= 9
                                ? `0${newExpiresAt.getDate()}`
                                : newExpiresAt.getDate();

                        formRef.current.setFieldValue(
                            'sleep_diary_subscription.new_expires_at',
                            `${year}-${month}-${day}`
                        );
                    } else {
                        const now = new Date();

                        const newExpiresAt = addMonths(now, Number(number));

                        const year = newExpiresAt.getFullYear();

                        const month =
                            newExpiresAt.getMonth() + 1 >= 10
                                ? newExpiresAt.getMonth() + 1
                                : `0${newExpiresAt.getMonth() + 1}`;

                        const day =
                            newExpiresAt.getDate() <= 9
                                ? `0${newExpiresAt.getDate()}`
                                : newExpiresAt.getDate();

                        formRef.current.setFieldValue(
                            'sleep_diary_subscription.new_expires_at',
                            `${year}-${month}-${day}`
                        );
                    }
                }

                if (action === 'change_plan') {
                    const now = new Date();

                    const newExpiresAt = addMonths(now, Number(number));

                    const year = newExpiresAt.getFullYear();

                    const month =
                        newExpiresAt.getMonth() + 1 >= 10
                            ? newExpiresAt.getMonth() + 1
                            : `0${newExpiresAt.getMonth() + 1}`;

                    const day =
                        newExpiresAt.getDate() <= 9
                            ? `0${newExpiresAt.getDate()}`
                            : newExpiresAt.getDate();

                    formRef.current.setFieldValue(
                        'sleep_diary_subscription.new_expires_at',
                        `${year}-${month}-${day}`
                    );
                }
            }

            if (type === 'lumus') {
                if (action === 'extend_paid') {
                    if (lumusSubscription.expires_at) {
                        const expiresAt = handleDate(
                            lumusSubscription.expires_at
                        );

                        const newExpiresAt = addMonths(
                            expiresAt,
                            Number(number)
                        );

                        const year = newExpiresAt.getFullYear();

                        const month =
                            newExpiresAt.getMonth() + 1 >= 10
                                ? newExpiresAt.getMonth() + 1
                                : `0${newExpiresAt.getMonth() + 1}`;

                        const day =
                            newExpiresAt.getDate() <= 9
                                ? `0${newExpiresAt.getDate()}`
                                : newExpiresAt.getDate();

                        formRef.current.setFieldValue(
                            'lumus_subscription.new_expires_at',
                            `${year}-${month}-${day}`
                        );
                    } else {
                        const now = new Date();

                        const newExpiresAt = addMonths(now, Number(number));

                        const year = newExpiresAt.getFullYear();

                        const month =
                            newExpiresAt.getMonth() + 1 >= 10
                                ? newExpiresAt.getMonth() + 1
                                : `0${newExpiresAt.getMonth() + 1}`;

                        const day =
                            newExpiresAt.getDate() <= 9
                                ? `0${newExpiresAt.getDate()}`
                                : newExpiresAt.getDate();

                        formRef.current.setFieldValue(
                            'lumus_subscription.new_expires_at',
                            `${year}-${month}-${day}`
                        );
                    }
                }

                if (action === 'change_plan') {
                    const now = new Date();

                    const newExpiresAt = addMonths(now, Number(number));

                    const year = newExpiresAt.getFullYear();

                    const month =
                        newExpiresAt.getMonth() + 1 >= 10
                            ? newExpiresAt.getMonth() + 1
                            : `0${newExpiresAt.getMonth() + 1}`;

                    const day =
                        newExpiresAt.getDate() <= 9
                            ? `0${newExpiresAt.getDate()}`
                            : newExpiresAt.getDate();

                    formRef.current.setFieldValue(
                        'lumus_subscription.new_expires_at',
                        `${year}-${month}-${day}`
                    );
                }
            }
        } else {
            if (type === 'sleep_diary') {
                formRef.current.setFieldValue(
                    'sleep_diary_subscription.paid_months',
                    1
                );

                formRef.current.setFieldValue(
                    'sleep_diary_subscription.new_expires_at',
                    ''
                );
            }

            if (type === 'lumus') {
                formRef.current.setFieldValue(
                    'lumus_subscription.paid_months',
                    1
                );

                formRef.current.setFieldValue(
                    'lumus_subscription.new_expires_at',
                    ''
                );
            }
        }
    }

    async function handleSaveSubscriptionPlan(type) {
        let data = {};
        let schema = null;

        if (type === 'sleep_diary') {
            data = formRef.current.getData().sleep_diary_subscription;
        } else {
            data = formRef.current.getData().lumus_subscription;
        }

        const { action, new_expires_at, new_plan_id } = data;

        if (action === 'extend_trial') {
            schema = schemas.extendTrialSchema;
        } else if (action === 'activate_trial') {
            schema = schemas.activateTrialSchema;
        } else if (action === 'activate') {
            schema = schemas.activateSchema;
        } else if (action === 'suspend') {
            schema = schemas.suspendSchema;
        } else if (action === 'change_plan') {
            if (type === 'sleep_diary') {
                const foundPlan = sleepDiaryPlans.find(
                    (plan) => plan.id === new_plan_id
                );

                if (foundPlan) {
                    if (foundPlan.name.includes('Unlimited')) {
                        schema = schemas.changeUnlimitedPlanSchema;
                    } else {
                        schema = schemas.changePlanSchema;
                    }
                } else {
                    schema = schemas.changePlanSchema;
                }
            } else {
                const foundPlan = lumusPlans.find(
                    (plan) => plan.id === new_plan_id
                );

                if (foundPlan) {
                    if (foundPlan.name.includes('Unlimited')) {
                        schema = schemas.changeUnlimitedPlanLumusSchema;
                    } else {
                        schema = schemas.changePlanLumusSchema;
                    }
                } else {
                    schema = schemas.changePlanLumusSchema;
                }
            }
        } else if (action === 'extend_paid') {
            schema = schemas.extendPaidSchema;
        }

        if (schema) {
            const { parsedData, isValid, errors } = await validateForm(
                data,
                schema
            );

            const fixedErros = Object.keys(errors).reduce((acc, key) => {
                acc[`${type}_subscription.${key}`] = errors[key];
                return acc;
            }, {});

            formRef.current.setErrors(fixedErros);

            if (isValid) {
                let response;

                dispatch(setLoading(true));

                if (action === 'extend_trial') {
                    response = await extendTrialSubscription(subscription.id, {
                        type,
                        days_to_extend: parsedData.days_to_extend,
                    });
                }

                if (action === 'activate_trial') {
                    response = await reactivateTrialSubscription(
                        subscription.id,
                        {
                            type,
                            expires_at: new_expires_at,
                        }
                    );
                }

                if (action === 'activate') {
                    response = await activateSubscription(
                        subscription.id,
                        parsedData.plan_id,
                        true
                    );
                }

                if (action === 'suspend') {
                    response = await activateSubscription(
                        subscription.id,
                        parsedData.plan_id,
                        false
                    );
                }

                if (action === 'change_plan') {
                    response = await changePlanSubscription(subscription.id, {
                        ...parsedData,
                        type,
                    });
                }

                if (action === 'extend_paid') {
                    response = await extendPaidSubscription(subscription.id, {
                        ...parsedData,
                        type,
                    });
                }

                dispatch(setLoading(false));

                if (response) {
                    toast.success(texts.successText);

                    setSubscription(response.subscription);

                    if (type === 'sleep_diary') {
                        setIsSleepDiarySubscriptionSaveButtonDisabled(true);
                        setIsSleepDiarySubscriptionCancelButtonDisabled(true);
                    } else {
                        setIsLumusSubscriptionSaveButtonDisabled(true);
                        setIsLumusSubscriptionCancelButtonDisabled(true);
                    }
                }
            }
        }
    }

    async function handleAddSubscriptionPlan(type) {
        let data = {};
        let schema = null;

        if (type === 'sleep_diary') {
            data = formRef.current.getData().sleep_diary_subscription;

            const foundPlan = sleepDiaryPlans.find(
                (plan) => plan.id === data?.new_plan_id
            );

            if (foundPlan) {
                if (foundPlan.name.includes('Unlimited')) {
                    schema = schemas.addUnlimitedPlanSchema;
                } else {
                    schema = schemas.addPlanSchema;
                }
            } else {
                schema = schemas.addPlanSchema;
            }
        } else {
            data = formRef.current.getData().lumus_subscription;

            const foundPlan = lumusPlans.find(
                (plan) => plan.id === data?.new_plan_id
            );

            if (foundPlan) {
                if (foundPlan.name.includes('Unlimited')) {
                    schema = schemas.addUnlimitedPlanLumusSchema;
                } else {
                    schema = schemas.addPlanLumusSchema;
                }
            } else {
                schema = schemas.addPlanLumusSchema;
            }
        }

        const { parsedData, isValid, errors } = await validateForm(
            data,
            schema
        );

        const fixedErros = Object.keys(errors).reduce((acc, key) => {
            acc[`${type}_subscription.${key}`] = errors[key];
            return acc;
        }, {});

        formRef.current.setErrors(fixedErros);

        if (isValid) {
            dispatch(setLoading(true));

            const response = await addPlanToSubscription(subscription.id, {
                ...parsedData,
                type,
            });

            dispatch(setLoading(false));

            if (response) {
                toast.success(texts.successText);

                setSubscription(response.subscription);
            }
        }
    }

    useEffect(() => {
        let suspendedMessage = null;

        if (subscription.suspended_reason) {
            if (subscription.suspended_reason.includes('-')) {
                const [suspendedReason, additionalMessage] =
                    subscription.suspended_reason.split('-');

                if (suspendedReason.trim() === 'trial_expired') {
                    suspendedMessage = `${
                        texts.trialExpiredReasonText
                    } - ${additionalMessage.trim()}`;
                }

                if (suspendedReason.trim() === 'invalid_payment_method') {
                    suspendedMessage = `${
                        texts.invalidPaymentMethodReasonText
                    } - ${additionalMessage.trim()}`;
                }

                if (suspendedReason.trim() === 'invoice_expired') {
                    suspendedMessage = `${
                        texts.invoiceExpiredReasonText
                    } - ${additionalMessage.trim()}`;
                }
            } else {
                if (subscription.suspended_reason === 'trial_expired') {
                    suspendedMessage = texts.trialExpiredReasonText;
                }

                if (
                    subscription.suspended_reason === 'invalid_payment_method'
                ) {
                    suspendedMessage = texts.invalidPaymentMethodReasonText;
                }

                if (subscription.suspended_reason === 'invoice_expired') {
                    suspendedMessage = texts.invoiceExpiredReasonText;
                }
            }
        }

        let initialData = {
            company: {
                name: company.name,
                user_name: user.name,
                email: user.email,
            },
            subscription: {
                price: currencyFormatter(
                    subscription.price,
                    currency.locale,
                    currency.format
                ),
                billing_date: subscription.billing_date
                    ? localeDateFormatter(subscription.billing_date, dateLocale)
                        ? localeDateFormatter(
                              subscription.billing_date,
                              dateLocale
                          )
                        : 'N/A'
                    : 'N/A',
                suspended_reason: suspendedMessage,
                expires_at: subscription.expires_at
                    ? localeDateFormatter(subscription.expires_at, dateLocale)
                        ? localeDateFormatter(
                              subscription.expires_at,
                              dateLocale
                          )
                        : 'N/A'
                    : 'N/A',
            },
            sleep_diary_subscription: {
                plan_id: {
                    value: sleepDiarySubscription?.plan?.id,
                    label: sleepDiarySubscription?.plan?.name,
                },
                max_patients: sleepDiarySubscription?.max_patients,
                price: currencyFormatter(
                    sleepDiarySubscription?.price,
                    currency.locale,
                    currency.format
                ),
                active: {
                    value: sleepDiarySubscription?.active,
                    label: sleepDiarySubscription?.active
                        ? texts.yesText
                        : texts.noText,
                },
                suspended: {
                    value: sleepDiarySubscription?.suspended,
                    label: sleepDiarySubscription?.suspended
                        ? texts.yesText
                        : texts.noText,
                },
                expires_at: sleepDiarySubscription?.expires_at
                    ? sleepDiarySubscription?.expires_at?.slice(0, 10)
                    : 'N/A',
            },
            lumus_subscription: {
                plan_id: {
                    value: lumusSubscription?.plan?.id,
                    label: lumusSubscription?.plan?.name,
                },
                max_devices: lumusSubscription?.max_devices,
                price: currencyFormatter(
                    lumusSubscription?.price,
                    currency.locale,
                    currency.format
                ),
                active: {
                    value: lumusSubscription?.active,
                    label: lumusSubscription?.active
                        ? texts.yesText
                        : texts.noText,
                },
                suspended: {
                    value: lumusSubscription?.suspended,
                    label: lumusSubscription?.suspended
                        ? texts.yesText
                        : texts.noText,
                },
                expires_at: lumusSubscription?.expires_at
                    ? lumusSubscription?.expires_at?.slice(0, 10)
                    : 'N/A',
            },
            coupons: subscription.discounts.map((discount, index) => ({
                coupon_id: {
                    value: discount.coupon_id,
                    label: coupons.find((c) => c.id === discount.coupon_id)
                        .name,
                },
                month_to_remove: discount.month_to_remove,
                year_to_remove: discount.year_to_remove,
            })),
        };

        formRef.current.setData(initialData);

        let active = formRef.current.getFieldValue('active');
        let suspended = formRef.current.getFieldValue('suspended');

        if (active !== undefined && active !== '') {
            formRef.current.setFieldValue('active', {
                label: active ? texts.yesText : texts.noText,
                value: active,
            });
        } else {
            formRef.current.setFieldValue('active', {
                label: subscription.active ? texts.yesText : texts.noText,
                value: subscription.active,
            });
        }

        if (suspended !== undefined && suspended !== '') {
            formRef.current.setFieldValue('suspended', {
                label: suspended ? texts.yesText : texts.noText,
                value: suspended,
            });
        } else {
            formRef.current.setFieldValue('suspended', {
                label: subscription.suspended ? texts.yesText : texts.noText,
                value: subscription.suspended,
            });
        }
    }, [lang, subscription]);

    return (
        <Form ref={formRef}>
            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.companyText}</span>
                </FormGroup>
            </FormGroup>

            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-4">
                    <Input
                        name="company.name"
                        type="text"
                        label={texts.nameText}
                        readOnly
                    />
                </FormGroup>
                <FormGroup className="col-xs-12 col-md-4">
                    <Input
                        name="company.user_name"
                        type="text"
                        label={texts.responsibleText}
                        readOnly
                    />
                </FormGroup>
                <FormGroup className="col-xs-12 col-md-4">
                    <Input
                        name="company.email"
                        type="text"
                        label={texts.emailText}
                        readOnly
                    />
                </FormGroup>
            </FormGroup>

            <hr />

            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.subscriptionText}</span>
                </FormGroup>
            </FormGroup>

            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-2">
                    <Input
                        name="subscription.price"
                        type="text"
                        label={texts.totalPriceText}
                        readOnly
                    />
                </FormGroup>
                <FormGroup className="col-xs-12 col-md-3">
                    <Input
                        name="subscription.billing_date"
                        type="text"
                        label={texts.billingDateText}
                        readOnly
                    />
                </FormGroup>
                {subscription.suspended_reason && (
                    <FormGroup
                        className={`"col-xs-12 ${
                            subscription.suspended_reason.includes(
                                'invalid_payment_method'
                            )
                                ? 'col-md-6'
                                : 'col-md-3'
                        }`}
                    >
                        <Input
                            name="subscription.suspended_reason"
                            type="text"
                            label={texts.suspendedReasonText}
                            readOnly
                        />
                    </FormGroup>
                )}
            </FormGroup>

            <hr />

            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.sleepDiarySubscriptionText}</span>
                </FormGroup>
            </FormGroup>

            {sleepDiarySubscription ? (
                <>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="sleep_diary_subscription.plan_id"
                                label={texts.planText}
                                options={[]}
                                isDisabled
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="sleep_diary_subscription.max_patients"
                                type="number"
                                label={texts.numberOfPatientsText}
                                disabled
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-2">
                            <Input
                                name="sleep_diary_subscription.price"
                                type="text"
                                label={texts.priceText}
                                disabled
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-2">
                            <Select
                                name="sleep_diary_subscription.active"
                                label={texts.activeText}
                                options={[
                                    {
                                        value: true,
                                        label: texts.yesText,
                                    },
                                    {
                                        value: false,
                                        label: texts.noText,
                                    },
                                ]}
                                isDisabled
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-2">
                            <Select
                                name="sleep_diary_subscription.suspended"
                                label={texts.suspendedText}
                                options={[
                                    {
                                        value: true,
                                        label: texts.yesText,
                                    },
                                    {
                                        value: false,
                                        label: texts.noText,
                                    },
                                ]}
                                isDisabled
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="sleep_diary_subscription.expires_at"
                                type={
                                    sleepDiarySubscription?.expires_at !== null
                                        ? 'date'
                                        : 'text'
                                }
                                label={texts.expiryDateText}
                                disabled
                            />
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="sleep_diary_subscription.action"
                                label={texts.actionText}
                                options={actionOptions.sleep_diary}
                                onChange={(e) =>
                                    handleChangeAction(e, 'sleep_diary')
                                }
                                isDisabled={
                                    actionOptions.sleep_diary.length === 0
                                }
                            />
                        </FormGroup>

                        {chosenAction.sleep_diary === 'extend_trial' && (
                            <FormGroup className="col-xs-12 col-md-2">
                                <Input
                                    name="sleep_diary_subscription.days_to_extend"
                                    type="number"
                                    label={texts.daysToExtendText}
                                    min={1}
                                    defaultValue={1}
                                />
                            </FormGroup>
                        )}

                        {chosenAction.sleep_diary === 'activate_trial' && (
                            <FormGroup className="col-xs-12 col-md-3">
                                <Input
                                    name="sleep_diary_subscription.new_expires_at"
                                    type="date"
                                    label={texts.newExpiryDateText}
                                />
                            </FormGroup>
                        )}

                        {chosenAction.sleep_diary === 'extend_paid' && (
                            <>
                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="sleep_diary_subscription.paid_months"
                                        type="number"
                                        min={1}
                                        label={texts.numberOfPaidMonthsText}
                                        required
                                        onChange={(e) =>
                                            handleNumberOfPaidMonthsChange(
                                                e?.target?.value,
                                                'extend_paid',
                                                'sleep_diary'
                                            )
                                        }
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="sleep_diary_subscription.new_expires_at"
                                        type="date"
                                        label={texts.newExpiryDateText}
                                        disabled
                                    />
                                </FormGroup>
                            </>
                        )}

                        {chosenAction.sleep_diary === 'change_plan' && (
                            <>
                                <FormGroup className="col-xs-12 col-md-3">
                                    <Select
                                        name="sleep_diary_subscription.new_plan_id"
                                        label={texts.planText}
                                        options={sleepDiaryPlans.map(
                                            (plan) => ({
                                                value: plan.id,
                                                label: plan.name,
                                            })
                                        )}
                                        onChange={(e) =>
                                            handlePlanChange(
                                                e?.value,
                                                'sleep_diary'
                                            )
                                        }
                                        required
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="sleep_diary_subscription.new_max_patients"
                                        type="number"
                                        label={texts.numberOfPatientsText}
                                        disabled
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-2">
                                    <Input
                                        name="sleep_diary_subscription.new_price"
                                        type="text"
                                        label={texts.priceText}
                                        disabled
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="sleep_diary_subscription.new_expires_at"
                                        type="date"
                                        label={texts.newExpiryDateText}
                                        disabled
                                    />
                                </FormGroup>

                                {showSleepDiaryPaidMonthsInput && (
                                    <FormGroup className="col-xs-12 col-md-3">
                                        <Input
                                            name="sleep_diary_subscription.paid_months"
                                            type="number"
                                            min={1}
                                            label={texts.numberOfPaidMonthsText}
                                            required
                                            onChange={(e) =>
                                                handleNumberOfPaidMonthsChange(
                                                    e?.target?.value,
                                                    'change_plan',
                                                    'sleep_diary'
                                                )
                                            }
                                        />
                                    </FormGroup>
                                )}
                            </>
                        )}
                    </FormGroup>

                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                className="mr-2"
                                type="button"
                                disabled={
                                    isSleepDiarySubscriptionSaveButtonDisabled
                                }
                                onClick={() =>
                                    handleSaveSubscriptionPlan('sleep_diary')
                                }
                            >
                                {texts.saveText}
                            </Button>

                            <Button
                                type="button"
                                color="secondary"
                                disabled={
                                    isSleepDiarySubscriptionCancelButtonDisabled
                                }
                                onClick={() =>
                                    handleResetSubscriptionPlan('sleep_diary')
                                }
                            >
                                {texts.cancelText}
                            </Button>
                        </div>
                    </FormGroup>
                </>
            ) : showAddSleepDiaryPlanButton ? (
                <FormGroup row>
                    <div className="col-xs-12 col-md-12 text-left">
                        <Button
                            type="button"
                            color="primary"
                            className="mr-2"
                            onClick={() => {
                                setShowAddSleepDiaryPlanButton(false);
                                setIsSleepDiarySubscriptionAddButtonDisabled(
                                    false
                                );
                                setIsSleepDiarySubscriptionCancelButtonDisabled(
                                    false
                                );
                            }}
                        >
                            {texts.addPlanText}
                        </Button>
                    </div>
                </FormGroup>
            ) : (
                <>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="sleep_diary_subscription.new_plan_id"
                                label={texts.planText}
                                options={sleepDiaryPlans.map((plan) => ({
                                    value: plan.id,
                                    label: plan.name,
                                }))}
                                onChange={(e) =>
                                    handlePlanChange(e?.value, 'sleep_diary')
                                }
                                required
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="sleep_diary_subscription.new_max_patients"
                                type="number"
                                label={texts.numberOfPatientsText}
                                disabled
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="sleep_diary_subscription.new_price"
                                type="text"
                                label={texts.priceText}
                                disabled
                            />
                        </FormGroup>

                        {showSleepDiaryPaidMonthsInput && (
                            <FormGroup className="col-xs-12 col-md-3">
                                <Input
                                    name="sleep_diary_subscription.paid_months"
                                    type="number"
                                    min={1}
                                    label={texts.numberOfPaidMonthsText}
                                    required
                                    onChange={(e) =>
                                        handleNumberOfPaidMonthsChange(
                                            e?.target?.value,
                                            'change_plan',
                                            'sleep_diary'
                                        )
                                    }
                                />
                            </FormGroup>
                        )}

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="sleep_diary_subscription.new_expires_at"
                                type="date"
                                label={texts.expiryDateText}
                                required={showSleepDiaryNewExpiresAtInput}
                                readOnly
                            />
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                className="mr-2"
                                type="button"
                                onClick={() =>
                                    handleAddSubscriptionPlan('sleep_diary')
                                }
                                disabled={
                                    isSleepDiarySubscriptionAddButtonDisabled
                                }
                            >
                                {texts.addText}
                            </Button>

                            <Button
                                type="button"
                                color="secondary"
                                onClick={() =>
                                    setShowAddSleepDiaryPlanButton(true)
                                }
                                disabled={
                                    isSleepDiarySubscriptionCancelButtonDisabled
                                }
                            >
                                {texts.cancelText}
                            </Button>
                        </div>
                    </FormGroup>
                </>
            )}

            <hr />

            <FormGroup row>
                <FormGroup className="col-xs-12 col-md-12">
                    <span>{texts.lumusSubscriptionText}</span>
                </FormGroup>
            </FormGroup>

            {lumusSubscription ? (
                <>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="lumus_subscription.plan_id"
                                label={texts.planText}
                                options={[]}
                                isDisabled
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="lumus_subscription.max_devices"
                                type="number"
                                label={texts.numberOfDevicesText}
                                disabled
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-2">
                            <Input
                                name="lumus_subscription.price"
                                type="text"
                                label={texts.priceText}
                                disabled
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-2">
                            <Select
                                name="lumus_subscription.active"
                                type="text"
                                label={texts.activeText}
                                options={[
                                    {
                                        value: true,
                                        label: texts.yesText,
                                    },
                                    {
                                        value: false,
                                        label: texts.noText,
                                    },
                                ]}
                                isDisabled
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-2">
                            <Select
                                name="lumus_subscription.suspended"
                                type="text"
                                label={texts.suspendedText}
                                options={[
                                    {
                                        value: true,
                                        label: texts.yesText,
                                    },
                                    {
                                        value: false,
                                        label: texts.noText,
                                    },
                                ]}
                                isDisabled
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-2">
                            <Input
                                name="lumus_subscription.expires_at"
                                type={
                                    lumusSubscription?.expires_at !== null
                                        ? 'date'
                                        : 'text'
                                }
                                label={texts.expiryDateText}
                                disabled
                            />
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="lumus_subscription.action"
                                label={texts.actionText}
                                options={actionOptions.lumus}
                                onChange={(e) => handleChangeAction(e, 'lumus')}
                                isDisabled={actionOptions.lumus.length === 0}
                            />
                        </FormGroup>

                        {chosenAction.lumus === 'extend_trial' && (
                            <FormGroup className="col-xs-12 col-md-2">
                                <Input
                                    name="lumus_subscription.days_to_extend"
                                    type="number"
                                    label={texts.daysToExtendText}
                                    min={1}
                                    defaultValue={1}
                                />
                            </FormGroup>
                        )}

                        {chosenAction.lumus === 'activate_trial' && (
                            <FormGroup className="col-xs-12 col-md-3">
                                <Input
                                    name="lumus_subscription.new_expires_at"
                                    type="date"
                                    label={texts.newExpiryDateText}
                                />
                            </FormGroup>
                        )}

                        {chosenAction.lumus === 'extend_paid' && (
                            <>
                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="lumus_subscription.paid_months"
                                        type="number"
                                        min={1}
                                        label={texts.numberOfPaidMonthsText}
                                        required
                                        onChange={(e) =>
                                            handleNumberOfPaidMonthsChange(
                                                e?.target?.value,
                                                'extend_paid',
                                                'lumus'
                                            )
                                        }
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="lumus_subscription.new_expires_at"
                                        type="date"
                                        label={texts.newExpiryDateText}
                                        disabled
                                    />
                                </FormGroup>
                            </>
                        )}

                        {chosenAction.lumus === 'change_plan' && (
                            <>
                                <FormGroup className="col-xs-12 col-md-3">
                                    <Select
                                        name="lumus_subscription.new_plan_id"
                                        label={texts.planText}
                                        options={lumusPlans.map((plan) => ({
                                            value: plan.id,
                                            label: plan.name,
                                        }))}
                                        onChange={(e) =>
                                            handlePlanChange(e?.value, 'lumus')
                                        }
                                        required
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="lumus_subscription.new_max_devices"
                                        type="number"
                                        label={texts.numberOfDevicesText}
                                        disabled={isMaxDevicesInputDisabled}
                                        required
                                        onChange={(e) =>
                                            handleNumberOfDevicesChange(
                                                e?.target?.value
                                            )
                                        }
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-2">
                                    <Input
                                        name="lumus_subscription.new_price"
                                        type="text"
                                        label={texts.priceText}
                                        disabled
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="lumus_subscription.new_expires_at"
                                        type="date"
                                        label={texts.newExpiryDateText}
                                        disabled
                                    />
                                </FormGroup>

                                {showLumusPaidMonthsInput && (
                                    <FormGroup className="col-xs-12 col-md-3">
                                        <Input
                                            name="lumus_subscription.paid_months"
                                            type="number"
                                            min={1}
                                            label={texts.numberOfPaidMonthsText}
                                            required
                                            onChange={(e) =>
                                                handleNumberOfPaidMonthsChange(
                                                    e?.target?.value,
                                                    'change_plan',
                                                    'lumus'
                                                )
                                            }
                                        />
                                    </FormGroup>
                                )}
                            </>
                        )}
                    </FormGroup>

                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                className="mr-2"
                                type="button"
                                disabled={isLumusSubscriptionSaveButtonDisabled}
                                onClick={() =>
                                    handleSaveSubscriptionPlan('lumus')
                                }
                            >
                                {texts.saveText}
                            </Button>

                            <Button
                                type="button"
                                color="secondary"
                                disabled={
                                    isLumusSubscriptionCancelButtonDisabled
                                }
                                onClick={() =>
                                    handleResetSubscriptionPlan('lumus')
                                }
                            >
                                {texts.cancelText}
                            </Button>
                        </div>
                    </FormGroup>
                </>
            ) : showAddLumusPlanButton ? (
                <FormGroup row>
                    <div className="col-xs-12 col-md-12 text-left">
                        <Button
                            type="button"
                            color="primary"
                            className="mr-2"
                            onClick={() => {
                                setShowAddLumusPlanButton(false);
                                setIsLumusSubscriptionAddButtonDisabled(false);
                                setIsLumusSubscriptionCancelButtonDisabled(
                                    false
                                );
                            }}
                        >
                            {texts.addPlanText}
                        </Button>
                    </div>
                </FormGroup>
            ) : (
                <>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="lumus_subscription.new_plan_id"
                                label={texts.planText}
                                options={lumusPlans.map((plan) => ({
                                    value: plan.id,
                                    label: plan.name,
                                }))}
                                onChange={(e) =>
                                    handlePlanChange(e?.value, 'lumus')
                                }
                                required
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="lumus_subscription.new_max_devices"
                                type="number"
                                label={texts.numberOfDevicesText}
                                disabled={isMaxDevicesInputDisabled}
                                required
                                onChange={(e) =>
                                    handleNumberOfDevicesChange(
                                        e?.target?.value
                                    )
                                }
                            />
                        </FormGroup>

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="lumus_subscription.new_price"
                                type="text"
                                label={texts.priceText}
                                disabled
                            />
                        </FormGroup>

                        {showLumusPaidMonthsInput && (
                            <FormGroup className="col-xs-12 col-md-3">
                                <Input
                                    name="lumus_subscription.paid_months"
                                    type="number"
                                    min={1}
                                    label={texts.numberOfPaidMonthsText}
                                    required
                                    onChange={(e) =>
                                        handleNumberOfPaidMonthsChange(
                                            e?.target?.value,
                                            'change_plan',
                                            'lumus'
                                        )
                                    }
                                />
                            </FormGroup>
                        )}

                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="lumus_subscription.new_expires_at"
                                type="date"
                                label={texts.expiryDateText}
                                required={showLumusNewExpiresAtInput}
                                readOnly
                            />
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                className="mr-2"
                                type="button"
                                onClick={() =>
                                    handleAddSubscriptionPlan('lumus')
                                }
                                disabled={isLumusSubscriptionAddButtonDisabled}
                            >
                                {texts.addText}
                            </Button>

                            <Button
                                type="button"
                                color="secondary"
                                onClick={() => setShowAddLumusPlanButton(true)}
                                disabled={
                                    isLumusSubscriptionCancelButtonDisabled
                                }
                            >
                                {texts.cancelText}
                            </Button>
                        </div>
                    </FormGroup>
                </>
            )}

            <hr />

            <CouponFields
                formRef={formRef}
                company={company}
                subscription={subscription}
                setSubscription={setSubscription}
                fields={couponsFields}
                schema={schemas.couponSchema}
                addCouponField={addCouponField}
                removeCouponField={removeCouponField}
                texts={{
                    couponsText: texts.couponsText,
                    addCouponText: texts.addCouponText,
                    deleteCouponText: texts.deleteCouponText,
                    applyCouponSuccessText: texts.applyCouponSuccessText,
                    updateCouponSuccessText: texts.updateCouponSuccessText,
                    removeCouponSuccessText: texts.removeCouponSuccessText,
                    saveText: texts.saveText,
                    cancelText: texts.cancelText,
                    successText: texts.successText,
                    couponNameText: texts.couponNameText,
                    expiryMonthText: texts.expiryMonthText,
                    expiryYearText: texts.expiryYearText,
                }}
                coupons={coupons}
            />

            <hr />

            <Row>
                <div className="col-xs-12 col-md-12 text-left">
                    <Link to="/companies" className="btn btn-secondary">
                        {texts.goBackText}
                    </Link>
                </div>
            </Row>
        </Form>
    );
}
