import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            couponSchema: Yup.array().of(
                Yup.object().shape({
                    coupon_id: Yup.number().required('Campo obrigatório'),
                    month_to_remove: Yup.number()
                        .min(1)
                        .max(12)
                        .required('Campo obrigatório'),
                    year_to_remove: Yup.number()
                        .min(new Date().getFullYear())
                        .max(2999)
                        .required('Campo obrigatório'),
                })
            ),
            extendTrialSchema: Yup.object().shape({
                days_to_extend: Yup.number()
                    .min(1)
                    .required('Campo obrigatório'),
            }),
            activateTrialSchema: Yup.object().shape({
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
            }),
            activateSchema: Yup.object().shape({
                plan_id: Yup.number().required('Campo obrigatório'),
            }),
            suspendSchema: Yup.object().shape({
                plan_id: Yup.number().required('Campo obrigatório'),
            }),
            extendPaidSchema: Yup.object().shape({
                plan_id: Yup.number().required('Campo obrigatório'),
                paid_months: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Campo obrigatório'),
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Campo obrigatório'),
            }),
            changePlanSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Campo obrigatório'),
                new_price: Yup.string(),
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('Campo obrigatório'),
                paid_months: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .when('new_price', {
                        is: (val) =>
                            val !== 'R$ 0,00' && val !== '$0.00' && val !== '',
                        then: (s) => s.required('Campo obrigatório'),
                        otherwise: (s) => s,
                    }),
            }),
            changeUnlimitedPlanSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Campo obrigatório'),
                new_price: Yup.string(),
            }),
            changePlanLumusSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Campo obrigatório'),
                new_max_devices: Yup.number().required('Campo obrigatório'),
                new_price: Yup.string(),
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('Campo obrigatório'),
                paid_months: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .when('new_price', {
                        is: (val) =>
                            val !== 'R$ 0,00' && val !== '$0.00' && val !== '',
                        then: (s) => s.required('Campo obrigatório'),
                        otherwise: (s) => s,
                    }),
            }),
            changeUnlimitedPlanLumusSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Campo obrigatório'),
                new_max_devices: Yup.number().required('Campo obrigatório'),
                new_price: Yup.string(),
            }),
            addPlanSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Campo obrigatório'),
                new_price: Yup.string(),
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('Campo obrigatório'),
                paid_months: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .when('new_price', {
                        is: (val) =>
                            val !== 'R$ 0,00' && val !== '$0.00' && val !== '',
                        then: (s) => s.required('Campo obrigatório'),
                        otherwise: (s) => s,
                    }),
            }),
            addUnlimitedPlanSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Campo obrigatório'),
                new_price: Yup.string(),
            }),
            addPlanLumusSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Campo obrigatório'),
                new_max_devices: Yup.number().required('Campo obrigatório'),
                new_price: Yup.string(),
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('Campo obrigatório'),
                paid_months: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .when('new_price', {
                        is: (val) =>
                            val !== 'R$ 0,00' && val !== '$0.00' && val !== '',
                        then: (s) => s.required('Campo obrigatório'),
                        otherwise: (s) => s,
                    }),
            }),
            addUnlimitedPlanLumusSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Campo obrigatório'),
                new_max_devices: Yup.number().required('Campo obrigatório'),
                new_price: Yup.string(),
            }),
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            couponSchema: Yup.array().of(
                Yup.object().shape({
                    coupon_id: Yup.number().required('Required field'),
                    month_to_remove: Yup.number()
                        .min(1)
                        .max(12)
                        .required('Required field'),
                    year_to_remove: Yup.number()
                        .min(new Date().getFullYear())
                        .max(2999)
                        .required('Required field'),
                })
            ),
            extendTrialSchema: Yup.object().shape({
                days_to_extend: Yup.number().min(1).required('Required field'),
            }),
            activateTrialSchema: Yup.object().shape({
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr)),
            }),
            activateSchema: Yup.object().shape({
                plan_id: Yup.number().required('Required field'),
            }),
            suspendSchema: Yup.object().shape({
                plan_id: Yup.number().required('Required field'),
            }),
            extendPaidSchema: Yup.object().shape({
                plan_id: Yup.number().required('Required field'),
                paid_months: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Required field'),
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Required field'),
            }),
            changePlanSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Required field'),
                new_price: Yup.string(),
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('Required field'),
                paid_months: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .when('new_price', {
                        is: (val) =>
                            val !== 'R$ 0,00' && val !== '$0.00' && val !== '',
                        then: (s) => s.required('Required field'),
                        otherwise: (s) => s,
                    }),
            }),
            changeUnlimitedPlanSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Required field'),
                new_price: Yup.string(),
            }),
            changePlanLumusSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Required field'),
                new_max_devices: Yup.number().required('Required field'),
                new_price: Yup.string(),
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('Required field'),
                paid_months: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .when('new_price', {
                        is: (val) =>
                            val !== 'R$ 0,00' && val !== '$0.00' && val !== '',
                        then: (s) => s.required('Required field'),
                        otherwise: (s) => s,
                    }),
            }),
            changeUnlimitedPlanLumusSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Required field'),
                new_max_devices: Yup.number().required('Required field'),
                new_price: Yup.string(),
            }),
            addPlanSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Required field'),
                new_price: Yup.string(),
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('Required field'),
                paid_months: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .when('new_price', {
                        is: (val) =>
                            val !== 'R$ 0,00' && val !== '$0.00' && val !== '',
                        then: (s) => s.required('Required field'),
                        otherwise: (s) => s,
                    }),
            }),
            addUnlimitedPlanSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Required field'),
                new_price: Yup.string(),
            }),
            addPlanLumusSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Required field'),
                new_max_devices: Yup.number().required('Required field'),
                new_price: Yup.string(),
                new_expires_at: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
                    .required('Required field'),
                paid_months: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .when('new_price', {
                        is: (val) =>
                            val !== 'R$ 0,00' && val !== '$0.00' && val !== '',
                        then: (s) => s.required('Required field'),
                        otherwise: (s) => s,
                    }),
            }),
            addUnlimitedPlanLumusSchema: Yup.object().shape({
                new_plan_id: Yup.number()
                    .transform((curr, orig) => (orig === '' ? undefined : curr))
                    .required('Required field'),
                new_max_devices: Yup.number().required('Required field'),
                new_price: Yup.string(),
            }),
        },
    },
};
