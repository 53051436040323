import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { BsSmartwatch } from 'react-icons/bs';
import { FaUsers, FaInfinity } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ShepherdTourContext } from 'react-shepherd';
import PropTypes from 'prop-types';

import styles from '../styles.module.scss';

import { InfoCard } from '../../../components/infoCard';
import Table from '../../../components/table/table';
import Td from '../../../components/table/td';
import { RenderDays } from './renderDays';
import { handleDate } from '../../../helpers/dateFormat';
import { getFilledDaysPercentage } from '../../../helpers/customerDashboard';
import { localeDateFormatter } from '../../../helpers/formatters';

CustomerDashboard.propTypes = {
    dashboardData: PropTypes.shape({
        last_ten_days: PropTypes.shape({
            date: PropTypes.arrayOf(PropTypes.string),
            timestamp: PropTypes.arrayOf(PropTypes.number),
        }),
        subordinates: PropTypes.arrayOf(PropTypes.object),
        active_company_devices: PropTypes.number,
        active_company_patients: PropTypes.number,
    }),
    pageData: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
};

export function CustomerDashboard({ dashboardData, pageData, currentUser }) {
    const tour = useContext(ShepherdTourContext);

    const { dateLocale } = useSelector((state) => state.locale);

    const [subordinates, setSubordinates] = useState([]);

    const sleepDiarySubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'sleep_diary'
    );

    const lumusSubscription = currentUser.subscription.plans.find(
        (subscriptionPlan) => subscriptionPlan.type === 'lumus'
    );

    useEffect(() => {
        if (dashboardData) {
            if (dashboardData.subordinates.length === 0) {
                toast.info(pageData.noPatientsText);
            }

            if (currentUser.permissions === 'manager') {
                const showWelcomeTour = JSON.parse(
                    localStorage.getItem('showWelcomeTour')
                );
                const showFirstPatientTour = JSON.parse(
                    localStorage.getItem('showFirstPatientTour')
                );
                const showNotFilledYetTour = JSON.parse(
                    localStorage.getItem('showNotFilledYetTour')
                );

                if (
                    showWelcomeTour ||
                    showFirstPatientTour ||
                    showNotFilledYetTour
                ) {
                    tour.start();
                }
            }

            const tempSubordinates = dashboardData.subordinates.map(
                (subordinate) => {
                    if (subordinate.trials_configurations.trialStart !== null) {
                        let trialStart = handleDate(
                            subordinate.trials_configurations.trialStart
                        );

                        if (trialStart) {
                            subordinate.trials_configurations.timestampTrialStart =
                                trialStart.getTime() / 1000;
                        }
                    }

                    subordinate.filled_days_percentage =
                        getFilledDaysPercentage(subordinate);

                    return subordinate;
                }
            );

            setSubordinates(tempSubordinates);
        }
    }, []);

    if (!dashboardData) {
        return null;
    }

    return (
        <>
            <Row>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={
                            sleepDiarySubscription
                                ? dashboardData.active_company_patients
                                : 0
                        }
                        title={pageData.activePatientsCardTitle}
                        icon={FaUsers}
                        className="cardDashboardTour blueBackground"
                        data-cy="active_patients_card"
                    />
                </Col>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={
                            sleepDiarySubscription
                                ? sleepDiarySubscription?.plan?.name !==
                                  'Unlimited'
                                    ? sleepDiarySubscription?.plan
                                          .max_patients -
                                      dashboardData.active_company_patients
                                    : FaInfinity
                                : 0
                        }
                        title={pageData.activePatientsToBeAddedCardTitle}
                        icon={FaUsers}
                        className="cardDashboardTour purpleBackground"
                    />
                </Col>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={
                            lumusSubscription
                                ? dashboardData.active_company_devices
                                : 0
                        }
                        title={pageData.activeDevicesText}
                        icon={BsSmartwatch}
                        className="cardDashboardTour greenBackground"
                    />
                </Col>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={
                            lumusSubscription
                                ? lumusSubscription?.plan?.name !==
                                  'Lumus Unlimited'
                                    ? lumusSubscription?.max_devices -
                                      dashboardData.active_company_devices
                                    : FaInfinity
                                : 0
                        }
                        title={pageData.remainingDevicesQuotaText}
                        icon={BsSmartwatch}
                        className="cardDashboardTour graphiteBackground"
                    />
                </Col>
            </Row>

            <Row>
                <Col xs="12" md="12" lg="12" xl="12">
                    <Card
                        className={`dashboardTableRowTour ${styles.cardShadow}`}
                    >
                        <CardHeader className={styles.cardHeader}>
                            <CardTitle className={styles.cardTitle}>
                                <h6>{pageData.tableTitle}</h6>
                            </CardTitle>
                        </CardHeader>
                        <CardBody className={styles.cardWrapperTableBody}>
                            <Table
                                className={`dashboardTableTour ${styles.dashboardTable} ${styles['table-striped']}`}
                            >
                                <thead>
                                    <tr>
                                        <th>{pageData.patientText}</th>
                                        <th>{pageData.startText}</th>
                                        <th>{pageData.currentWeekText}</th>
                                        <th>
                                            {pageData.filledDaysPercentageText}
                                        </th>
                                        <th>
                                            {pageData.lastTenDaysFillmentText}
                                        </th>
                                        <th>{pageData.devicesText}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subordinates.map((subordinate) => (
                                        <tr key={subordinate.id}>
                                            <Td title={pageData.patientText}>
                                                <Link
                                                    to={{
                                                        pathname:
                                                            'users/report/',
                                                        state: {
                                                            entity: subordinate,
                                                        },
                                                    }}
                                                >
                                                    {subordinate.name}
                                                </Link>
                                            </Td>

                                            <Td title={pageData.startText}>
                                                {localeDateFormatter(
                                                    subordinate
                                                        .trials_configurations
                                                        .trialStart,
                                                    dateLocale
                                                ) ?? pageData.noDataText}
                                            </Td>

                                            <Td
                                                title={pageData.currentWeekText}
                                            >
                                                {subordinate.current_week ??
                                                    pageData.noDataText}
                                            </Td>

                                            <Td
                                                title={
                                                    pageData.filledDaysPercentageText
                                                }
                                                className={
                                                    subordinate.filled_days_percentage ===
                                                    null
                                                        ? ''
                                                        : subordinate.filled_days_percentage <=
                                                          33
                                                        ? 'textRed'
                                                        : subordinate.filled_days_percentage >
                                                              33 &&
                                                          subordinate.filled_days_percentage <=
                                                              66
                                                        ? 'textOrange'
                                                        : 'textGreen'
                                                }
                                            >
                                                {subordinate.filled_days_percentage ===
                                                null
                                                    ? pageData.noDataText
                                                    : `${subordinate.filled_days_percentage}%`}
                                            </Td>

                                            <Td
                                                title={
                                                    pageData.lastTenDaysFillmentText
                                                }
                                            >
                                                <RenderDays
                                                    lastNDays={
                                                        dashboardData.last_ten_days
                                                    }
                                                    subordinate={subordinate}
                                                    locale={dateLocale}
                                                />
                                            </Td>

                                            <Td title={pageData.devicesText}>
                                                <div
                                                    className={
                                                        styles.devicesContainer
                                                    }
                                                >
                                                    {subordinate.devices
                                                        .length > 0 ? (
                                                        subordinate.devices.map(
                                                            (device) => (
                                                                <p
                                                                    key={
                                                                        device[
                                                                            'serial_no'
                                                                        ]
                                                                    }
                                                                >
                                                                    {
                                                                        device[
                                                                            'serial_no'
                                                                        ]
                                                                    }
                                                                </p>
                                                            )
                                                        )
                                                    ) : (
                                                        <p>--</p>
                                                    )}
                                                </div>
                                            </Td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
