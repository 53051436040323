import * as Yup from 'yup';

export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            schema: Yup.object().shape({
                company: Yup.object().shape({
                    name: Yup.string().required('Campo obrigatório'),
                    share: Yup.bool()
                        .transform((curr, orig) =>
                            orig === '' ? undefined : curr
                        )
                        .required('Campo obrigatório'),
                    descriptor: Yup.object().shape({
                        saveEmail: Yup.bool()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .required('Campo obrigatório'),
                        defaultLanguage:
                            Yup.string().required('Campo obrigatório'),
                        minPasswordLength: Yup.number()
                            .min(8)
                            .max(30)
                            .required('Campo obrigatório'),
                    }),
                }),
                locale: Yup.string().required('Campo obrigatório'),
                user: Yup.object().shape({
                    name: Yup.string().required('Campo obrigatório'),
                    secondary_id: Yup.string()
                        .nullable()
                        .transform((curr, orig) => (orig === '' ? null : curr)),
                    email: Yup.string()
                        .email('Formato inválido')
                        .required('Campo obrigatório'),
                    role_id: Yup.number().required('Campo obrigatório'),
                    active: Yup.bool()
                        .transform((curr, orig) =>
                            orig === '' ? undefined : curr
                        )
                        .required('Campo obrigatório'),
                }),
                sleep_diary_subscription: Yup.object()
                    .notRequired()
                    .default(undefined)
                    .shape({
                        plan_id: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .required('Campo obrigatório'),
                        max_patients: Yup.number().transform((curr, orig) =>
                            orig === '' ? undefined : curr
                        ),
                        price_brl: Yup.string(),
                        price_usd: Yup.string(),
                        paid_months: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .when('price_brl', {
                                is: (val) => val !== 'R$ 0,00' && val !== '',
                                then: (s) => s.required('Campo obrigatório'),
                                otherwise: (s) => s,
                            }),
                    }),
                lumus_subscription: Yup.object()
                    .notRequired()
                    .default(undefined)
                    .shape({
                        plan_id: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .required('Campo obrigatório'),
                        max_devices: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .required('Campo obrigatório'),
                        price_brl: Yup.string(),
                        price_usd: Yup.string(),
                        paid_months: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .when('price_brl', {
                                is: (val) => val !== 'R$ 0,00' && val !== '',
                                then: (s) => s.required('Campo obrigatório'),
                                otherwise: (s) => s,
                            }),
                    }),
            }),
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            schema: Yup.object().shape({
                company: Yup.object().shape({
                    name: Yup.string().required('Required field'),
                    share: Yup.bool()
                        .transform((curr, orig) =>
                            orig === '' ? undefined : curr
                        )
                        .required('Required field'),
                    descriptor: Yup.object().shape({
                        saveEmail: Yup.bool()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .required('Required field'),
                        defaultLanguage:
                            Yup.string().required('Required field'),
                        minPasswordLength: Yup.number()
                            .min(8)
                            .max(30)
                            .required('Required field'),
                    }),
                }),
                locale: Yup.string().required('Required field'),
                user: Yup.object().shape({
                    name: Yup.string().required('Required field'),
                    secondary_id: Yup.string()
                        .nullable()
                        .transform((curr, orig) => (orig === '' ? null : curr)),
                    email: Yup.string()
                        .email('Invalid format')
                        .required('Required field'),
                    role_id: Yup.number().required('Required field'),
                    active: Yup.bool()
                        .transform((curr, orig) =>
                            orig === '' ? undefined : curr
                        )
                        .required('Required field'),
                }),
                sleep_diary_subscription: Yup.object()
                    .notRequired()
                    .default(undefined)
                    .shape({
                        plan_id: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .required('Required field'),
                        max_patients: Yup.number().transform((curr, orig) =>
                            orig === '' ? undefined : curr
                        ),
                        price_brl: Yup.string(),
                        price_usd: Yup.string(),
                        paid_months: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .when('price_brl', {
                                is: (val) => val !== 'R$ 0,00' && val !== '',
                                then: (s) => s.required('Required field'),
                                otherwise: (s) => s,
                            }),
                    }),
                lumus_subscription: Yup.object()
                    .notRequired()
                    .default(undefined)
                    .shape({
                        plan_id: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .required('Required field'),
                        max_devices: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .required('Required field'),
                        price_brl: Yup.string(),
                        price_usd: Yup.string(),
                        paid_months: Yup.number()
                            .transform((curr, orig) =>
                                orig === '' ? undefined : curr
                            )
                            .when('price_brl', {
                                is: (val) => val !== 'R$ 0,00' && val !== '',
                                then: (s) => s.required('Required field'),
                                otherwise: (s) => s,
                            }),
                    }),
            }),
        },
    },
};
