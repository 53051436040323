export default {
    pt: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            companyText: 'Empresa',
            nameText: 'Nome',
            namePlaceholderText: 'Digite o nome da empresa...',
            shareText: 'Compartilha pacientes?',
            savePatientsEmailText: 'Salvar e-Mail de pacientes?',
            defaultLanguageText: 'Idioma padrão',
            portugueseText: 'Português',
            englishText: 'Inglês',
            minimumPasswordLengthText: 'Tamanho mínimo de senha',
            localeText: 'Localidade',
            nationalText: 'Nacional',
            internationalText: 'Internacional',
            userText: 'Gestor Administrador',
            userNameText: 'Nome',
            userNamePlaceholderText: 'Digite o nome do usuário...',
            secondaryIdText: 'ID secundário',
            secondaryIdPlaceholderText: 'Digite o id secundário do usuário...',
            emailText: 'e-Mail',
            emailPlaceholderText: 'Digite o e-Mail do usuário...',
            userTypeText: 'Tipo de usuário',
            managerAdminText: 'Gestor Administrador',
            activeText: 'Ativo',
            sleepDiarySubscriptionText: 'Assinatura do Diário de Sono',
            lumusSubscriptionText: 'Assinatura do Lumus',
            addPlanText: 'Adicionar plano',
            removePlanText: 'Remover plano',
            planText: 'Plano',
            numberOfPatientsText: 'Número de pacientes',
            nationalPriceText: 'Preço (reais)',
            internationalPriceText: 'Preço (dólares)',
            numberOfDevicesText: 'Número de dispositivos',
            numberOfPaidMonthsText: 'Número de meses pagos',
            addSuccessText: 'Empresa adicionada com sucesso!',
            addButtonText: 'Adicionar',
            cancelButtonText: 'Cancelar',
            requiredFieldText: 'Campos obrigatórios!',
            yesText: 'Sim',
            noText: 'Não',
        },
    },
    en: {
        customer_admin: null,
        manager: null,
        condor_admin: {
            companyText: 'Company',
            nameText: 'Name',
            namePlaceholderText: 'Enter the company name...',
            shareText: 'Share patients?',
            savePatientsEmailText: 'Save patient e-Mails?',
            defaultLanguageText: 'Default language',
            portugueseText: 'Portuguese',
            englishText: 'English',
            minimumPasswordLengthText: 'Minimum password length',
            localeText: 'Locale',
            nationalText: 'National',
            internationalText: 'International',
            userText: 'Manager Admin',
            userNameText: 'Name',
            userNamePlaceholderText: 'Enter the username...',
            secondaryIdText: 'Secondary ID',
            secondaryIdPlaceholderText: 'Enter the secondary user id...',
            emailText: 'e-Mail',
            emailPlaceholderText: `Enter the user's e-mail...`,
            userTypeText: 'User type',
            managerAdminText: 'Manager Admin',
            activeText: 'Active',
            sleepDiarySubscriptionText: 'Sleep Diary subscription',
            lumusSubscriptionText: 'Lumus subscription',
            addPlanText: 'Add plan',
            removePlanText: 'Remove plan',
            planText: 'Plan',
            numberOfPatientsText: 'Number of patients',
            nationalPriceText: 'Price (reais)',
            internationalPriceText: 'Price (dollars)',
            numberOfDevicesText: 'Number of devices',
            numberOfPaidMonthsText: 'Number of months paid',
            addSuccessText: 'Company successfully added!',
            addButtonText: 'Add',
            cancelButtonText: 'Cancel',
            requiredFieldText: 'Required fields!',
            yesText: 'Yes',
            noText: 'No',
        },
    },
};
