import { add } from 'date-fns';

export function addDays(date, days) {
    return add(date, {
        days,
    });
}

export function addMonths(date, months) {
    return add(date, {
        months,
    });
}
