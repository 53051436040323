import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from '../styles.module.scss';

import { WeekDays } from './weekDays';
import { MonthDays } from './monthDays';
import useWindowSize from '../../../hooks/useWindowSize';

RenderDays.propTypes = {
    lastNDays: PropTypes.object.isRequired,
    subordinate: PropTypes.object.isRequired,
    locale: PropTypes.object.isRequired,
};

export function RenderDays({ lastNDays, subordinate, locale }) {
    const windowSize = useWindowSize();

    return windowSize.width >= 1224 ? (
        <>
            <Row className={styles.daysContainer}>
                <Col xs="12">
                    <WeekDays lastNDays={lastNDays} />
                </Col>
                <Col xs="12">
                    <MonthDays
                        lastNDays={lastNDays}
                        subordinate={subordinate}
                        locale={locale}
                    />
                </Col>
            </Row>
        </>
    ) : (
        <>
            <Row className={styles.daysContainer}>
                <Col xs="12">
                    <WeekDays lastNDays={lastNDays} limit={'smaller'} />
                </Col>
                <Col xs="12">
                    <MonthDays
                        lastNDays={lastNDays}
                        subordinate={subordinate}
                        locale={locale}
                        limit={'smaller'}
                    />
                </Col>
                <Col xs="12">
                    <WeekDays lastNDays={lastNDays} limit={'bigger'} />
                </Col>
                <Col xs="12">
                    <MonthDays
                        lastNDays={lastNDays}
                        subordinate={subordinate}
                        locale={locale}
                        limit={'bigger'}
                    />
                </Col>
            </Row>
        </>
    );
}
