import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import { history } from '../../../services/history';
import { fetchCouponsByCurrency } from '../../../services/api/coupons';
import { fetchCompanySubscription } from '../../../services/api/account';
import { fetchPlans } from '../../../services/api/plans';
import { MyForm as Form } from './components/form';
import { setLoading as showLoading } from '../../../store/actions/loadingActions';

CompanySubscription.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function CompanySubscription({ currentUser }) {
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState({});
    const [user, setUser] = useState({});
    const [subscription, setSubscription] = useState({});
    const [coupons, setCoupons] = useState(null);
    const [couponsFields, setCouponsFields] = useState([]);
    const [currency, setCurrency] = useState({});
    const [actionOptions, setActionOptions] = useState({
        sleep_diary: [],
        lumus: [],
    });
    const [sleepDiaryPlans, setSleepDiaryPlans] = useState([]);
    const [lumusPlans, setLumusPlans] = useState([]);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];
    const schemas = schemaConfig[lang][currentUser.permissions];

    function removeCouponField(index) {
        let tempCoupons = [...couponsFields];

        tempCoupons.splice(index, 1);

        setCouponsFields(tempCoupons);
    }

    function addCouponField() {
        let tempCoupons = [...couponsFields];

        tempCoupons.push(crypto.randomUUID());

        setCouponsFields(tempCoupons);
    }

    useEffect(() => {
        if (history.location.state?.entity) {
            setCompany(history.location.state.entity);
        } else {
            history.push('/companies');
        }

        (async function () {
            dispatch(showLoading(true));
            setLoading(true);

            const subscriptionData = await fetchCompanySubscription(
                history.location.state?.entity.id
            );

            const couponsData = await fetchCouponsByCurrency(
                subscriptionData?.subscription?.locale === 'pt-BR'
                    ? 'BRL'
                    : 'USD'
            );

            const plansData = await fetchPlans();

            if (subscriptionData) {
                setCurrency({
                    locale:
                        subscriptionData?.subscription?.locale === 'pt-BR'
                            ? 'pt-BR'
                            : 'en-US',
                    format:
                        subscriptionData?.subscription?.locale === 'pt-BR'
                            ? 'BRL'
                            : 'USD',
                });

                setUser({
                    ...subscriptionData?.user,
                    user_name: subscriptionData?.user.name,
                });

                setSubscription(subscriptionData?.subscription);

                let tempActionOptions = {
                    sleep_diary: [],
                    lumus: [],
                };

                subscriptionData?.subscription.plans.forEach(
                    (subscriptionPlan) => {
                        if (subscriptionPlan.plan.name.includes('Trial')) {
                            if (subscriptionPlan.active) {
                                tempActionOptions[subscriptionPlan.type].push({
                                    value: 'extend_trial',
                                    label: pageData.extendTrialText,
                                });
                                tempActionOptions[subscriptionPlan.type].push({
                                    value: 'suspend',
                                    label: pageData.suspendText,
                                });
                            } else {
                                tempActionOptions[subscriptionPlan.type].push({
                                    value: 'activate_trial',
                                    label: pageData.activateTrialText,
                                });
                            }
                        } else if (
                            subscriptionPlan.plan.name.includes('Unlimited')
                        ) {
                            if (subscriptionPlan.active) {
                                tempActionOptions[subscriptionPlan.type].push({
                                    value: 'suspend',
                                    label: pageData.suspendText,
                                });
                            } else {
                                tempActionOptions[subscriptionPlan.type].push({
                                    value: 'activate',
                                    label: pageData.activateText,
                                });
                            }
                        } else {
                            if (subscriptionPlan.active) {
                                tempActionOptions[subscriptionPlan.type].push({
                                    value: 'suspend',
                                    label: pageData.suspendText,
                                });

                                tempActionOptions[subscriptionPlan.type].push({
                                    value: 'extend_paid',
                                    label: pageData.extendPaidText,
                                });
                            } else {
                                tempActionOptions[subscriptionPlan.type].push({
                                    value: 'activate',
                                    label: pageData.activateText,
                                });
                            }
                        }

                        tempActionOptions[subscriptionPlan.type].push({
                            value: 'change_plan',
                            label: pageData.changePlanText,
                        });
                    }
                );

                setActionOptions(tempActionOptions);
            }

            if (couponsData) {
                setCouponsFields(
                    subscriptionData?.subscription?.discounts?.map((discount) =>
                        crypto.randomUUID()
                    )
                );
                setCoupons(couponsData?.coupons);
            }

            if (plansData) {
                setSleepDiaryPlans(
                    plansData?.plans?.filter(
                        (plan) => plan.type === 'sleep_diary'
                    )
                );
                setLumusPlans(
                    plansData?.plans?.filter((plan) => plan.type === 'lumus')
                );
            }

            dispatch(showLoading(false));
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        let tempActionOptions = {
            sleep_diary: [],
            lumus: [],
        };

        subscription?.plans?.forEach((subscriptionPlan) => {
            if (subscriptionPlan.plan.name.includes('Trial')) {
                if (subscriptionPlan.active) {
                    tempActionOptions[subscriptionPlan.type].push({
                        value: 'extend_trial',
                        label: pageData.extendTrialText,
                    });
                    tempActionOptions[subscriptionPlan.type].push({
                        value: 'suspend',
                        label: pageData.suspendText,
                    });
                } else {
                    tempActionOptions[subscriptionPlan.type].push({
                        value: 'activate_trial',
                        label: pageData.activateTrialText,
                    });
                }
            } else if (subscriptionPlan.plan.name.includes('Unlimited')) {
                if (subscriptionPlan.active) {
                    tempActionOptions[subscriptionPlan.type].push({
                        value: 'suspend',
                        label: pageData.suspendText,
                    });
                } else {
                    tempActionOptions[subscriptionPlan.type].push({
                        value: 'activate',
                        label: pageData.activateText,
                    });
                }
            } else {
                if (subscriptionPlan.active) {
                    tempActionOptions[subscriptionPlan.type].push({
                        value: 'suspend',
                        label: pageData.suspendText,
                    });

                    tempActionOptions[subscriptionPlan.type].push({
                        value: 'extend_paid',
                        label: pageData.extendPaidText,
                    });
                } else {
                    tempActionOptions[subscriptionPlan.type].push({
                        value: 'activate',
                        label: pageData.activateText,
                    });
                }
            }

            tempActionOptions[subscriptionPlan.type].push({
                value: 'change_plan',
                label: pageData.changePlanText,
            });
        });

        setActionOptions(tempActionOptions);
    }, [lang, subscription]);

    if (loading || coupons === null) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form
                    company={company}
                    user={user}
                    subscription={subscription}
                    setSubscription={setSubscription}
                    coupons={coupons}
                    couponsFields={couponsFields}
                    schemas={schemas}
                    currency={currency}
                    addCouponField={addCouponField}
                    removeCouponField={removeCouponField}
                    actionOptions={actionOptions}
                    sleepDiaryPlans={sleepDiaryPlans}
                    lumusPlans={lumusPlans}
                    texts={pageData}
                />
            </CardBody>
        </Card>
    );
}
