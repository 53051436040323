import React from 'react';
import PropTypes from 'prop-types';
import { parseISO, format } from 'date-fns';

import styles from '../styles.module.scss';

MonthDays.propTypes = {
    lastNDays: PropTypes.object.isRequired,
    subordinate: PropTypes.object.isRequired,
    limit: PropTypes.string,
    locale: PropTypes.object,
};

export function MonthDays({ lastNDays, subordinate, limit, locale }) {
    return lastNDays.date.map((day, index) => {
        let color = styles.red;
        let tourClassName = '';

        if (subordinate.data_input.find((di) => di.date === day)) {
            color = styles.green;
            tourClassName = 'greenCircleTour';
        } else if (subordinate.trials_configurations.trialStart === null) {
            color = styles.grey;
            tourClassName = 'greyCircleTour';
        } else if (
            subordinate.trials_configurations.timestampTrialStart >
            lastNDays.timestamp[index]
        ) {
            color = styles.grey;
            tourClassName = 'greyCircleTour';
        } else {
            color = styles.red;
            tourClassName = 'redCircleTour';
        }

        if (limit) {
            if (limit == 'smaller') {
                return (
                    index < 5 && (
                        <span
                            key={day}
                            className={`${styles.circle} ${color} ${tourClassName}`}
                        >
                            {format(parseISO(day), 'P', {
                                locale: locale,
                            }).substr(0, 5)}
                        </span>
                    )
                );
            } else {
                return (
                    index >= 5 && (
                        <span
                            key={day}
                            className={`${styles.circle} ${color} ${tourClassName}`}
                        >
                            {format(parseISO(day), 'P', {
                                locale: locale,
                            }).substr(0, 5)}
                        </span>
                    )
                );
            }
        } else {
            return (
                <span
                    key={day}
                    className={`${styles.circle} ${color} ${tourClassName}`}
                >
                    {format(parseISO(day), 'P', { locale: locale }).substr(
                        0,
                        5
                    )}
                </span>
            );
        }
    });
}
