import { REHYDRATE } from 'redux-persist/es/constants';
import { ptBR, enUS } from 'date-fns/locale';

import { isObject, isDate } from '../helpers/objectChecker';

function validate(state, value) {
    return (
        state &&
        typeof state === typeof value &&
        Object.keys(state).every((key) => key in value)
    );
}

const validateRehydrateMiddleware = (store) => (next) => (action) => {
    switch (action.type) {
        case REHYDRATE: {
            if (action.payload && typeof action.payload === 'object') {
                const newPayload = {};
                const state = store.getState();

                Object.keys(action.payload).forEach((key) => {
                    if (validate(state[key], action.payload[key])) {
                        newPayload[key] = action.payload[key];
                    }

                    if (key === 'auth') {
                        if (
                            action.payload[key]['currentUser'] !== null &&
                            (!isObject(action.payload[key]['currentUser']) ||
                                (isObject(action.payload[key]['currentUser']) &&
                                    Object.keys(
                                        action.payload[key]['currentUser']
                                    ).length === 0))
                        ) {
                            newPayload[key] = { ...state[key] };
                        }

                        if (
                            typeof action.payload[key]['signed'] !== typeof true
                        ) {
                            newPayload[key]['signed'] = isObject(
                                action.payload[key]['currentUser']
                            )
                                ? true
                                : false;
                        }

                        newPayload[key]['loading'] = false;

                        if (
                            typeof action.payload[key]['completedLoginFlow'] !==
                            typeof true
                        ) {
                            newPayload[key]['completedLoginFlow'] = false;
                        }

                        if (
                            typeof action.payload[key][
                                'maxDateToCompleteLoginFlow'
                            ] !== typeof 123
                        ) {
                            newPayload[key]['maxDateToCompleteLoginFlow'] =
                                null;
                        }
                    }

                    if (key === 'lang') {
                        if (
                            action.payload[key]['lang'] !== 'pt' &&
                            action.payload[key]['lang'] !== 'en'
                        ) {
                            newPayload[key] = { ...state[key] };
                        }
                    }

                    if (key === 'locale') {
                        if (
                            (action.payload[key]['currencyLocale'] !==
                                'pt-BR' &&
                                action.payload[key]['currencyLocale'] !==
                                    'en-US') ||
                            (action.payload[key]['currencyFormat'] !== 'BRL' &&
                                action.payload[key]['currencyFormat'] !==
                                    'USD') ||
                            (action.payload[key]['dateLocale'] !== ptBR &&
                                action.payload[key]['dateLocale'] !== enUS)
                        ) {
                            newPayload[key] = { ...state[key] };
                        }
                    }

                    if (key === 'report') {
                        if (
                            typeof action.payload[key]['minDate'] === typeof ''
                        ) {
                            if (!isDate(action.payload[key]['minDate'])) {
                                newPayload[key] = { ...state[key] };
                            }
                        }
                        if (
                            typeof action.payload[key]['maxdate'] === typeof ''
                        ) {
                            if (!isDate(action.payload[key]['maxdate'])) {
                                newPayload[key] = { ...state[key] };
                            }
                        }
                        if (
                            (action.payload[key]['reportData'] !== null &&
                                (!isObject(action.payload[key]['reportData']) ||
                                    (isObject(
                                        action.payload[key]['reportData']
                                    ) &&
                                        Object.keys(
                                            action.payload[key]['reportData']
                                        ).length === 0))) ||
                            (action.payload[key]['user'] !== null &&
                                (!isObject(action.payload[key]['user']) ||
                                    (isObject(action.payload[key]['user']) &&
                                        Object.keys(action.payload[key]['user'])
                                            .length === 0))) ||
                            (action.payload[key]['minDate'] !== undefined &&
                                action.payload[key]['minDate'] !== null &&
                                typeof action.payload[key]['minDate'] !==
                                    typeof '') ||
                            (action.payload[key]['maxDate'] !== undefined &&
                                action.payload[key]['maxDate'] !== null &&
                                typeof action.payload[key]['maxDate'] !==
                                    typeof '')
                        ) {
                            newPayload[key] = { ...state[key] };
                        }
                    }
                });

                action.payload = newPayload;
            }

            break;
        }
    }

    return next(action);
};

export default validateRehydrateMiddleware;
