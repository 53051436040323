import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, CardFooter, FormGroup, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import pageConfig from './page.config';
import schemaConfig from './schema.config';
import Input from '../../../components/inputForm/inputText';
import Select from '../../../components/inputForm/inputSelect';
import { setLoading } from '../../../store/actions/loadingActions';
import { validateForm } from '../../../helpers/formValidator';
import { currencyFormatter } from '../../../helpers/formatters';
import { getLumusPrice } from '../../../helpers/getLumusPrice';
import history from '../../../services/history';
import { addCompany } from '../../../services/api/companies';
import { fetchPlans } from '../../../services/api/plans';

AddCompany.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default function AddCompany({ currentUser }) {
    const formRef = useRef(null);

    const isFirstRender = useRef(true);

    const [sleepDiaryPlans, setSleepDiaryPlans] = useState([]);
    const [lumusPlans, setLumusPlans] = useState([]);
    const [addSleepDiaryPlan, setAddSleepDiaryPlan] = useState(false);
    const [addLumusPlan, setAddLumusPlan] = useState(false);
    const [showSleepDiaryPaidMonths, setShowSleepDiaryPaidMonths] =
        useState(false);
    const [showLumusPaidMonths, setShowLumusPaidMonths] = useState(false);
    const [maxDevicesDisabled, setMaxDevicesDisabled] = useState(false);

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.lang.lang);

    const pageData = pageConfig[lang][currentUser.permissions];

    const schemaData = schemaConfig[lang][currentUser.permissions];

    async function handleSubmit(data) {
        const { parsedData, isValid, errors } = await validateForm(
            data,
            schemaData.schema
        );

        formRef.current.setErrors(errors);

        if (isValid) {
            dispatch(setLoading(true));

            const response = await addCompany(parsedData);

            dispatch(setLoading(false));

            if (response) {
                toast.success(pageData.addSuccessText);

                history.push('/companies');
            }
        }
    }

    function handlePlanChange(planId, type) {
        if (planId) {
            let foundPlan;

            if (type === 'sleep_diary') {
                foundPlan = sleepDiaryPlans.find((plan) => plan.id === planId);

                setShowSleepDiaryPaidMonths(
                    !foundPlan.name.includes('Trial') &&
                        !foundPlan.name.includes('Unlimited')
                );

                formRef.current.setFieldValue(
                    'sleep_diary_subscription.max_patients',
                    foundPlan.max_patients
                );

                formRef.current.setFieldValue(
                    'sleep_diary_subscription.price_brl',
                    currencyFormatter(foundPlan.price_brl, 'pt-BR', 'BRL')
                );

                formRef.current.setFieldValue(
                    'sleep_diary_subscription.price_usd',
                    currencyFormatter(foundPlan.price_usd, 'en-US', 'USD')
                );
            }

            if (type === 'lumus') {
                foundPlan = lumusPlans.find((plan) => plan.id === planId);

                setMaxDevicesDisabled(
                    foundPlan.name.includes('Trial') ||
                        foundPlan.name.includes('Unlimited')
                );

                setShowLumusPaidMonths(
                    !foundPlan.name.includes('Trial') &&
                        !foundPlan.name.includes('Unlimited')
                );

                if (foundPlan.name.includes('Trial')) {
                    formRef.current.setFieldValue(
                        'lumus_subscription.max_devices',
                        5
                    );

                    formRef.current.setFieldValue(
                        'lumus_subscription.price_brl',
                        currencyFormatter(0, 'pt-BR', 'BRL')
                    );

                    formRef.current.setFieldValue(
                        'lumus_subscription.price_usd',
                        currencyFormatter(0, 'en-US', 'USD')
                    );
                } else if (foundPlan.name.includes('Unlimited')) {
                    formRef.current.setFieldValue(
                        'lumus_subscription.max_devices',
                        1000000
                    );

                    formRef.current.setFieldValue(
                        'lumus_subscription.price_brl',
                        currencyFormatter(0, 'pt-BR', 'BRL')
                    );

                    formRef.current.setFieldValue(
                        'lumus_subscription.price_usd',
                        currencyFormatter(0, 'en-US', 'USD')
                    );
                } else {
                    const max_devices = formRef.current.getFieldValue(
                        'lumus_subscription.max_devices'
                    );

                    if (max_devices) {
                        formRef.current.setFieldValue(
                            'lumus_subscription.price_brl',
                            currencyFormatter(
                                getLumusPrice(
                                    foundPlan.prices,
                                    Number(max_devices),
                                    'BRL'
                                ),
                                'pt-BR',
                                'BRL'
                            )
                        );

                        formRef.current.setFieldValue(
                            'lumus_subscription.price_usd',
                            currencyFormatter(
                                getLumusPrice(
                                    foundPlan.prices,
                                    Number(max_devices),
                                    'USD'
                                ),
                                'en-US',
                                'USD'
                            )
                        );
                    }
                }
            }
        }
    }

    function handleNumberOfDevicesChange(number) {
        if (number) {
            const planId = formRef.current.getFieldValue(
                'lumus_subscription.plan_id'
            );

            if (planId) {
                const foundPlan = lumusPlans.find((plan) => plan.id === planId);

                formRef.current.setFieldValue(
                    'lumus_subscription.price_brl',
                    currencyFormatter(
                        getLumusPrice(foundPlan.prices, Number(number), 'BRL'),
                        'pt-BR',
                        'BRL'
                    )
                );

                formRef.current.setFieldValue(
                    'lumus_subscription.price_usd',
                    currencyFormatter(
                        getLumusPrice(foundPlan.prices, Number(number), 'USD'),
                        'en-US',
                        'USD'
                    )
                );
            }
        } else {
            formRef.current.setFieldValue('lumus_subscription.price_brl', '');

            formRef.current.setFieldValue('lumus_subscription.price_usd', '');
        }
    }

    useEffect(() => {
        (async function () {
            if (isFirstRender.current) {
                isFirstRender.current = false;

                dispatch(setLoading(true));

                const data = await fetchPlans();

                dispatch(setLoading(false));

                if (data) {
                    setSleepDiaryPlans(
                        data?.plans?.filter(
                            (plan) => plan.type === 'sleep_diary'
                        )
                    );
                    setLumusPlans(
                        data?.plans?.filter((plan) => plan.type === 'lumus')
                    );
                }

                formRef.current.setData({
                    company: {
                        descriptor: {
                            minPasswordLength: 8,
                        },
                    },
                    user: {
                        role_id: {
                            label: pageData.managerAdminText,
                            value: 4,
                        },
                        active: {
                            label: pageData.yesText,
                            value: true,
                        },
                    },
                });
            } else {
                if (formRef.current.getFieldValue('company.share') !== '') {
                    formRef.current.setFieldValue('company.share', {
                        label: formRef.current.getFieldValue('company.share')
                            ? pageData.yesText
                            : pageData.noText,
                        value: formRef.current.getFieldValue('company.share'),
                    });
                }

                if (
                    formRef.current.getFieldValue(
                        'company.descriptor.saveEmail'
                    ) !== ''
                ) {
                    formRef.current.setFieldValue(
                        'company.descriptor.saveEmail',
                        {
                            label: formRef.current.getFieldValue(
                                'company.descriptor.saveEmail'
                            )
                                ? pageData.yesText
                                : pageData.noText,
                            value: formRef.current.getFieldValue(
                                'company.descriptor.saveEmail'
                            ),
                        }
                    );
                }

                if (
                    formRef.current.getFieldValue(
                        'company.descriptor.defaultLanguage'
                    ) !== ''
                ) {
                    formRef.current.setFieldValue(
                        'company.descriptor.defaultLanguage',
                        {
                            label:
                                formRef.current.getFieldValue(
                                    'company.descriptor.defaultLanguage'
                                ) === 'pt'
                                    ? pageData.portugueseText
                                    : pageData.englishText,
                            value: formRef.current.getFieldValue(
                                'company.descriptor.defaultLanguage'
                            ),
                        }
                    );
                }

                if (formRef.current.getFieldValue('locale') !== '') {
                    formRef.current.setFieldValue('locale', {
                        label: formRef.current.getFieldValue('locale')
                            ? pageData.nationalText
                            : pageData.internationalText,
                        value: formRef.current.getFieldValue('locale'),
                    });
                }

                if (formRef.current.getFieldValue('user.role_id') !== '') {
                    formRef.current.setFieldValue('user.role_id', {
                        label: pageData.managerAdminText,
                        value: 4,
                    });
                }

                if (formRef.current.getFieldValue('user.active') !== '') {
                    formRef.current.setFieldValue('user.active', {
                        label: formRef.current.getFieldValue('user.active')
                            ? pageData.yesText
                            : pageData.noText,
                        value: formRef.current.getFieldValue('user.active'),
                    });
                }

                if (Object.keys(formRef.current.getErrors()).length > 0) {
                    const { errors } = await validateForm(
                        formRef.current.getData(),
                        schemaData.schema
                    );

                    formRef.current.setErrors(errors);
                }
            }
        })();
    }, [lang]);

    if (pageData === null && schemaData === null) {
        return <></>;
    }

    return (
        <Card className="cardShadow">
            <CardBody>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{pageData.companyText}</span>
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-5">
                            <Input
                                name="company.name"
                                type="text"
                                label={pageData.nameText}
                                placeholder={pageData.namePlaceholderText}
                                required
                            />
                        </FormGroup>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="company.share"
                                label={pageData.shareText}
                                options={[
                                    { value: true, label: pageData.yesText },
                                    { value: false, label: pageData.noText },
                                ]}
                                required
                            />
                        </FormGroup>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="company.descriptor.saveEmail"
                                label={pageData.savePatientsEmailText}
                                options={[
                                    { value: true, label: pageData.yesText },
                                    { value: false, label: pageData.noText },
                                ]}
                                required
                            />
                        </FormGroup>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="company.descriptor.defaultLanguage"
                                label={pageData.defaultLanguageText}
                                options={[
                                    {
                                        value: 'pt',
                                        label: pageData.portugueseText,
                                    },
                                    {
                                        value: 'en',
                                        label: pageData.englishText,
                                    },
                                ]}
                                required
                            />
                        </FormGroup>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="company.descriptor.minPasswordLength"
                                type="number"
                                label={pageData.minimumPasswordLengthText}
                                min={8}
                                required
                            />
                        </FormGroup>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="locale"
                                label={pageData.localeText}
                                options={[
                                    {
                                        value: 'pt-BR',
                                        label: pageData.nationalText,
                                    },
                                    {
                                        value: 'en-US',
                                        label: pageData.internationalText,
                                    },
                                ]}
                                required
                            />
                        </FormGroup>
                    </FormGroup>

                    <hr />

                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{pageData.userText}</span>
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-5">
                            <Input
                                name="user.name"
                                type="text"
                                label={pageData.userNameText}
                                placeholder={pageData.userNamePlaceholderText}
                                required
                            />
                        </FormGroup>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="user.secondary_id"
                                type="text"
                                label={pageData.secondaryIdText}
                                placeholder={
                                    pageData.secondaryIdPlaceholderText
                                }
                            />
                        </FormGroup>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Input
                                name="user.email"
                                type="text"
                                label={pageData.emailText}
                                placeholder={pageData.emailPlaceholderText}
                                required
                            />
                        </FormGroup>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="user.role_id"
                                label={pageData.userTypeText}
                                options={[
                                    {
                                        value: 4,
                                        label: pageData.managerAdminText,
                                    },
                                ]}
                                isDisabled
                                required
                            />
                        </FormGroup>
                        <FormGroup className="col-xs-12 col-md-3">
                            <Select
                                name="user.active"
                                label={pageData.activeText}
                                options={[
                                    { value: true, label: pageData.yesText },
                                    { value: false, label: pageData.noText },
                                ]}
                                required
                            />
                        </FormGroup>
                    </FormGroup>

                    <hr />

                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{pageData.sleepDiarySubscriptionText}</span>
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        {addSleepDiaryPlan ? (
                            <>
                                <FormGroup className="col-xs-12 col-md-3">
                                    <Select
                                        name="sleep_diary_subscription.plan_id"
                                        label={pageData.planText}
                                        options={sleepDiaryPlans.map(
                                            (plan) => ({
                                                value: plan.id,
                                                label: plan.name,
                                            })
                                        )}
                                        onChange={(e) =>
                                            handlePlanChange(
                                                e?.value,
                                                'sleep_diary'
                                            )
                                        }
                                        required
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="sleep_diary_subscription.max_patients"
                                        type="number"
                                        label={pageData.numberOfPatientsText}
                                        disabled
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="sleep_diary_subscription.price_brl"
                                        type="text"
                                        label={pageData.nationalPriceText}
                                        disabled
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="sleep_diary_subscription.price_usd"
                                        type="text"
                                        label={pageData.internationalPriceText}
                                        disabled
                                    />
                                </FormGroup>

                                {showSleepDiaryPaidMonths && (
                                    <FormGroup className="col-xs-12 col-md-3">
                                        <Input
                                            name="sleep_diary_subscription.paid_months"
                                            type="number"
                                            min={1}
                                            label={
                                                pageData.numberOfPaidMonthsText
                                            }
                                            required
                                        />
                                    </FormGroup>
                                )}

                                <div className="col-xs-12 col-md-12 text-left">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={() =>
                                            setAddSleepDiaryPlan(false)
                                        }
                                    >
                                        {pageData.removePlanText}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <div className="col-xs-12 col-md-12 text-left">
                                <Button
                                    color="primary"
                                    className="mr-2"
                                    type="button"
                                    onClick={() => setAddSleepDiaryPlan(true)}
                                >
                                    {pageData.addPlanText}
                                </Button>
                            </div>
                        )}
                    </FormGroup>

                    <hr />

                    <FormGroup row>
                        <FormGroup className="col-xs-12 col-md-12">
                            <span>{pageData.lumusSubscriptionText}</span>
                        </FormGroup>
                    </FormGroup>

                    <FormGroup row>
                        {addLumusPlan ? (
                            <>
                                <FormGroup className="col-xs-12 col-md-3">
                                    <Select
                                        name="lumus_subscription.plan_id"
                                        label={pageData.planText}
                                        options={lumusPlans.map((plan) => ({
                                            value: plan.id,
                                            label: plan.name,
                                        }))}
                                        onChange={(e) =>
                                            handlePlanChange(e?.value, 'lumus')
                                        }
                                        required
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="lumus_subscription.max_devices"
                                        type="number"
                                        min={1}
                                        label={pageData.numberOfDevicesText}
                                        onChange={(e) =>
                                            handleNumberOfDevicesChange(
                                                e?.target?.value
                                            )
                                        }
                                        disabled={maxDevicesDisabled}
                                        required
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="lumus_subscription.price_brl"
                                        type="text"
                                        label={pageData.nationalPriceText}
                                        disabled
                                    />
                                </FormGroup>

                                <FormGroup className="col-xs-12 col-md-3">
                                    <Input
                                        name="lumus_subscription.price_usd"
                                        type="text"
                                        label={pageData.internationalPriceText}
                                        disabled
                                    />
                                </FormGroup>

                                {showLumusPaidMonths && (
                                    <FormGroup className="col-xs-12 col-md-3">
                                        <Input
                                            name="lumus_subscription.paid_months"
                                            type="number"
                                            min={1}
                                            label={
                                                pageData.numberOfPaidMonthsText
                                            }
                                            required
                                        />
                                    </FormGroup>
                                )}

                                <div className="col-xs-12 col-md-12 text-left">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={() => setAddLumusPlan(false)}
                                    >
                                        {pageData.removePlanText}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <div className="col-xs-12 col-md-12 text-left">
                                <Button
                                    color="primary"
                                    className="mr-2"
                                    type="button"
                                    onClick={() => setAddLumusPlan(true)}
                                >
                                    {pageData.addPlanText}
                                </Button>
                            </div>
                        )}
                    </FormGroup>

                    <hr />

                    <Row>
                        <div className="col-xs-12 col-md-12 text-left">
                            <Button
                                color="primary"
                                className="mr-2"
                                type="submit"
                            >
                                {pageData.addButtonText}
                            </Button>
                            <Link to="/companies" className="btn btn-secondary">
                                {pageData.cancelButtonText}
                            </Link>
                        </div>
                    </Row>
                </Form>
            </CardBody>
            <CardFooter>
                <p>
                    <b style={{ color: 'darkred' }}>*</b>{' '}
                    {pageData.requiredFieldText}
                </p>
            </CardFooter>
        </Card>
    );
}
