import React, { useState } from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
} from 'reactstrap';
import PropTypes from 'prop-types';

import styles from '../styles.module.scss';

import { AverageChartsContainer } from './averageChartsContainer';

export function AverageCardContainer({ title, texts, data }) {
    const [activeChart, setActiveChart] = useState('daily');

    const colors = ['#7f3fbf', '#bf7d3f', '#84ebb7'];

    function handleClick(name) {
        setActiveChart(name);
    }

    return (
        <Card className={styles.cardShadow}>
            <CardHeader className={styles.cardHeader}>
                <CardTitle
                    className={`${styles.cardTitle} d-flex justify-content-between align-items-center`}
                >
                    <h6>{title.toLocaleUpperCase()}</h6>
                    <div>
                        <Button
                            color={`${
                                activeChart === 'daily'
                                    ? 'primary'
                                    : 'secondary'
                            }`}
                            onClick={() => handleClick('daily')}
                        >
                            {texts.dayText}
                        </Button>
                        <Button
                            color={`${
                                activeChart === 'weekly'
                                    ? 'primary'
                                    : 'secondary'
                            }`}
                            onClick={() => handleClick('weekly')}
                        >
                            {texts.weekText}
                        </Button>
                        <Button
                            color={`${
                                activeChart === 'monthly'
                                    ? 'primary'
                                    : 'secondary'
                            }`}
                            onClick={() => handleClick('monthly')}
                        >
                            {texts.monthText}
                        </Button>
                    </div>
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Row className={styles.cardBodyContent}>
                    <Col xs="12" className={styles.chartsContainer}>
                        <AverageChartsContainer
                            type={activeChart}
                            charts={data.charts}
                            colors={colors}
                        />
                    </Col>
                    <Col xs="12">
                        <h6>{texts.averageText}</h6>
                    </Col>
                    <Col xs="12">
                        <Row>
                            <Col xs="12" md="4">
                                <div className={styles.widgetContent}>
                                    <div className={styles.division}>
                                        {Array.isArray(data.dailyAverage) ? (
                                            data.dailyAverage.map(
                                                (average, index) => (
                                                    <h6
                                                        key={`${average}${index}`}
                                                        style={{
                                                            color: colors[
                                                                index
                                                            ],
                                                        }}
                                                    >
                                                        {average}
                                                    </h6>
                                                )
                                            )
                                        ) : (
                                            <h5>{data.dailyAverage}</h5>
                                        )}
                                    </div>
                                    <span>{texts.byDayText}</span>
                                </div>
                            </Col>
                            <Col xs="12" md="4">
                                <div className={styles.widgetContent}>
                                    <div className={styles.division}>
                                        {Array.isArray(data.weeklyAverage) ? (
                                            data.weeklyAverage.map(
                                                (average, index) => (
                                                    <h6
                                                        key={`${average}${index}`}
                                                        style={{
                                                            color: colors[
                                                                index
                                                            ],
                                                        }}
                                                    >
                                                        {average}
                                                    </h6>
                                                )
                                            )
                                        ) : (
                                            <h5>{data.weeklyAverage}</h5>
                                        )}
                                    </div>
                                    <span>{texts.byWeekText}</span>
                                </div>
                            </Col>
                            <Col xs="12" md="4">
                                <div className={styles.widgetContent}>
                                    <div className={styles.division}>
                                        {Array.isArray(data.monthlyAverage) ? (
                                            data.monthlyAverage.map(
                                                (average, index) => (
                                                    <h6
                                                        key={`${average}${index}`}
                                                        style={{
                                                            color: colors[
                                                                index
                                                            ],
                                                        }}
                                                    >
                                                        {average}
                                                    </h6>
                                                )
                                            )
                                        ) : (
                                            <h5>{data.monthlyAverage}</h5>
                                        )}
                                    </div>
                                    <span>{texts.byMonthText}</span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

AverageCardContainer.propTypes = {
    title: PropTypes.string.isRequired,
    texts: PropTypes.shape({
        dayText: PropTypes.string.isRequired,
        weekText: PropTypes.string.isRequired,
        monthText: PropTypes.string.isRequired,
        averageText: PropTypes.string.isRequired,
        byDayText: PropTypes.string.isRequired,
        byWeekText: PropTypes.string.isRequired,
        byMonthText: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.shape({
        charts: PropTypes.shape({
            daily: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
            weekly: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
            monthly: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
        }).isRequired,
        dailyAverage: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
            .isRequired,
        weeklyAverage: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
            .isRequired,
        monthlyAverage: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
            .isRequired,
    }).isRequired,
};
