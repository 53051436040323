function pad(number) {
    if (number < 10) {
        return '0' + number;
    }
    return number;
}

export function getArraysOfLastNDays(n) {
    let lastNDays = { date: [], timestamp: [] };

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    for (let i = 1; i < n + 1; i++) {
        let date = `${today.getFullYear()}-${pad(today.getMonth() + 1)}-${pad(
            today.getDate()
        )}`;
        lastNDays.date.push(date);
        lastNDays.timestamp.push(today.getTime() / 1000);
        today.setDate(today.getDate() - 1);
    }

    lastNDays.date.reverse();
    lastNDays.timestamp.reverse();

    return lastNDays;
}

export function getFilledDaysPercentage(user) {
    let numberOfFilledDays = user.data_input.length;

    let today = new Date();
    today = new Date(
        `${today.getFullYear()}-${pad(today.getMonth() + 1)}-${pad(
            today.getDate()
        )}`
    );

    if (user.trials_configurations.trialStart === null) {
        return null;
    }

    let start = new Date(user.trials_configurations.trialStart);

    let differenceInTime = today.getTime() - start.getTime();
    let differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1;

    if (differenceInDays) {
        return Math.round((numberOfFilledDays / differenceInDays) * 100);
    }

    return 0;
}
