import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from '../styles.module.scss';

import { localeDateFormatter } from '../../../helpers/formatters';
import { handlePastDate } from '../../../helpers/handleActiveProperty';
import Table from '../../../components/table/table';
import { TableHeadersWithFilters as Ths } from '../../../components/table/tableHeadersWithFilters';
import { Td } from '../../../components/table/td';
import { CustomPagination as Pagination } from '../../../components/table/pagination';

UsageReportContainer.propTypes = {
    texts: PropTypes.shape({
        showText: PropTypes.string.isRequired,
        entriesText: PropTypes.string.isRequired,
        yesText: PropTypes.string.isRequired,
        noText: PropTypes.string.isRequired,
        showingText: PropTypes.string.isRequired,
        toText: PropTypes.string.isRequired,
        ofText: PropTypes.string.isRequired,
        wrongFormatText: PropTypes.string.isRequired,
    }).isRequired,
    ths: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            property: PropTypes.string.isRequired,
        })
    ).isRequired,
    filterThs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            property: PropTypes.string.isRequired,
        })
    ).isRequired,
    tds: PropTypes.shape({
        company_name: PropTypes.string.isRequired,
        plan_name: PropTypes.string.isRequired,
        active_patients: PropTypes.string.isRequired,
        active_devices: PropTypes.string.isRequired,
        formattedSubIsActive: PropTypes.string.isRequired,
        formattedSubIsSuspended: PropTypes.string.isRequired,
        formattedBillingDate: PropTypes.string.isRequired,
        formattedExpiresAt: PropTypes.string.isRequired,
        formattedLastVisit: PropTypes.string.isRequired,
        formattedLastDataInput: PropTypes.string.isRequired,
    }).isRequired,
    report: PropTypes.arrayOf(
        PropTypes.shape({
            company_id: PropTypes.number.isRequired,
            company_name: PropTypes.string.isRequired,
            user_name: PropTypes.string.isRequired,
            user_id: PropTypes.number.isRequired,
            user_is_active: PropTypes.bool.isRequired,
            plan_name: PropTypes.string.isRequired,
            max_patients: PropTypes.number.isRequired,
            active_patients: PropTypes.number.isRequired,
            active_devices: PropTypes.number.isRequired,
            sub_id: PropTypes.number.isRequired,
            sub_is_active: PropTypes.bool,
            formattedSubIsActive: PropTypes.string,
            sub_is_suspended: PropTypes.bool.isRequired,
            formattedSubIsSuspended: PropTypes.string,
            billing_date: PropTypes.string,
            formattedBillingDate: PropTypes.string,
            expires_at: PropTypes.string,
            formattedExpiresAt: PropTypes.string,
            last_visit: PropTypes.string,
            formattedLastVisit: PropTypes.string,
            last_data_input: PropTypes.string,
            formattedLastDataInput: PropTypes.string,
        })
    ),
    handleOpenCompanyReport: PropTypes.func.isRequired,
};

export function UsageReportContainer({
    texts,
    ths,
    filterThs,
    tds,
    report,
    handleOpenCompanyReport,
}) {
    const lang = useSelector((state) => state.lang.lang);
    const { dateLocale } = useSelector((state) => state.locale);

    const [filteredReport, setFilteredReport] = useState(report);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [entriesPositions, setEntriesPositions] = useState([
        1,
        entriesPerPage,
    ]);
    const [activeFilter, setActiveFilter] = useState({
        property: 'company_name',
        order: 'asc',
    });

    const currentTableData = useMemo(() => {
        const firstEntrieIndex = (currentPage - 1) * entriesPerPage;
        const lastEntrieIndex = firstEntrieIndex + entriesPerPage;

        setEntriesPositions([
            firstEntrieIndex >= filteredReport.length
                ? 1
                : firstEntrieIndex + 1,
            lastEntrieIndex > filteredReport.length
                ? filteredReport.length
                : lastEntrieIndex,
        ]);

        return filteredReport.slice(firstEntrieIndex, lastEntrieIndex);
    }, [currentPage, entriesPerPage, filteredReport]);

    const handleChangeNumberOfEntries = useCallback(
        (number) => {
            if (Number(number) > filteredReport.length) {
                setCurrentPage(1);
            }
            setEntriesPerPage(Number(number));
        },
        [filteredReport]
    );

    const orderByProperty = useCallback(
        (filterProperty, order) => {
            if (
                filterProperty !== activeFilter.property ||
                order !== activeFilter.order
            ) {
                let newFilterProperty;

                if (filterProperty === 'formattedBillingDate') {
                    newFilterProperty = 'billing_date';
                } else if (filterProperty === 'formattedExpiresAt') {
                    newFilterProperty = 'expires_at';
                } else if (filterProperty === 'formattedLastDataInput') {
                    newFilterProperty = 'last_data_input';
                } else if (filterProperty === 'formattedLastVisit') {
                    newFilterProperty = 'last_visit';
                } else {
                    newFilterProperty = filterProperty;
                }

                setActiveFilter({ property: filterProperty, order });

                let tempFilteredReport = [...filteredReport].sort((a, b) => {
                    if (
                        newFilterProperty === 'billing_date' ||
                        newFilterProperty === 'expires_at' ||
                        newFilterProperty === 'last_data_input' ||
                        newFilterProperty === 'last_visit'
                    ) {
                        let aDate = new Date(a[newFilterProperty]);
                        let bDate = new Date(b[newFilterProperty]);

                        if (aDate > bDate) {
                            return order === 'asc' ? 1 : -1;
                        }

                        if (aDate < bDate) {
                            return order === 'asc' ? -1 : 1;
                        }
                    } else if (typeof a[newFilterProperty] === 'string') {
                        if (
                            a[newFilterProperty].toUpperCase().trim() >
                            b[newFilterProperty].toUpperCase().trim()
                        ) {
                            return order === 'asc' ? 1 : -1;
                        }

                        if (
                            a[newFilterProperty].toUpperCase().trim() <
                            b[newFilterProperty].toUpperCase().trim()
                        ) {
                            return order === 'asc' ? -1 : 1;
                        }
                    } else {
                        if (a[newFilterProperty] > b[newFilterProperty]) {
                            return order === 'asc' ? 1 : -1;
                        }

                        if (a[newFilterProperty] < b[newFilterProperty]) {
                            return order === 'asc' ? -1 : 1;
                        }
                    }

                    return 0;
                });

                setFilteredReport(tempFilteredReport);
                setCurrentPage(1);
                setEntriesPositions([1, entriesPerPage]);
            }
        },
        [filteredReport, entriesPerPage, activeFilter]
    );

    useEffect(() => {
        let tempFormattedReport = filteredReport.map((data) => {
            return {
                ...data,
                formattedBillingDate: data.billing_date
                    ? localeDateFormatter(data.billing_date, dateLocale)
                        ? localeDateFormatter(data.billing_date, dateLocale)
                        : texts.wrongFormatText
                    : 'N/A',
                formattedExpiresAt: data.expires_at
                    ? localeDateFormatter(data.expires_at, dateLocale)
                        ? localeDateFormatter(data.expires_at, dateLocale)
                        : texts.wrongFormatText
                    : 'N/A',
                formattedLastDataInput: data.last_data_input
                    ? localeDateFormatter(data.last_data_input, dateLocale)
                        ? localeDateFormatter(data.last_data_input, dateLocale)
                        : texts.wrongFormatText
                    : 'N/A',
                formattedLastVisit: data.last_visit
                    ? localeDateFormatter(data.last_visit, dateLocale)
                        ? localeDateFormatter(data.last_visit, dateLocale)
                        : texts.wrongFormatText
                    : 'N/A',
                formattedSubIsActive: data.sub_is_active
                    ? texts.yesText
                    : texts.noText,
                formattedSubIsSuspended: data.sub_is_suspended
                    ? texts.yesText
                    : texts.noText,
                formattedUserIsActive: data.user_is_active
                    ? texts.yesText
                    : texts.noText,
            };
        });

        setFilteredReport(tempFormattedReport);

        orderByProperty(activeFilter.property, activeFilter.order);
    }, [lang]);

    return (
        <>
            <div className={styles.tableHeaderInfo}>
                <div>
                    <label
                        htmlFor="example_length"
                        className="d-flex justify-content-center align-items-center"
                    >
                        {texts.showText}{' '}
                        <Input
                            type="select"
                            id="example_length"
                            name="example_length"
                            onChange={(e) =>
                                handleChangeNumberOfEntries(e.target.value)
                            }
                            className="mx-1"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Input>{' '}
                        {texts.entriesText}
                    </label>
                </div>
            </div>
            <Table className={styles.tableBordered}>
                <thead>
                    <tr className={styles.FixedSizeTd}>
                        <Ths
                            ths={ths}
                            filterThs={filterThs}
                            orderByProperty={orderByProperty}
                            activeFilter={activeFilter}
                            classNames={{
                                active: styles.active,
                                clickableIcon: styles.clickableIcon,
                            }}
                        />
                    </tr>
                </thead>
                <tbody>
                    {currentTableData.map((data, index) => (
                        <tr
                            key={`${data.company_id}${index}`}
                            className={styles.clickableTr}
                            onClick={() =>
                                handleOpenCompanyReport(
                                    data.company_id,
                                    data.company_name
                                )
                            }
                        >
                            <Td title={tds.company_name}>
                                {data.company_name}
                            </Td>
                            <Td title={tds.plan_name}>{data.plan_name}</Td>
                            <Td title={tds.active_patients}>
                                {data.active_patients}
                            </Td>
                            <Td title={tds.active_devices}>
                                {data.active_devices}
                            </Td>
                            <Td
                                title={tds.formattedSubIsActive}
                                className={
                                    data.sub_is_active ? 'textGreen' : 'textRed'
                                }
                            >
                                {data.formattedSubIsActive}
                            </Td>
                            <Td
                                title={tds.formattedSubIsSuspended}
                                className={
                                    data.sub_is_suspended
                                        ? 'textGreen'
                                        : 'textRed'
                                }
                            >
                                {data.formattedSubIsSuspended}
                            </Td>
                            <Td
                                title={tds.formattedBillingDate}
                                className={handlePastDate(data.billing_date)}
                            >
                                {data.formattedBillingDate}
                            </Td>
                            <Td
                                title={tds.formattedExpiresAt}
                                className={handlePastDate(data.expires_at)}
                            >
                                {data.formattedExpiresAt}
                            </Td>
                            <Td title={tds.formattedLastVisit}>
                                {data.formattedLastVisit}
                            </Td>
                            <Td title={tds.formattedLastDataInput}>
                                {data.formattedLastDataInput}
                            </Td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className={styles.tableFooterInfo}>
                <p>
                    {texts.showingText} {entriesPositions[0]} {texts.toText}{' '}
                    {entriesPositions[1]} {texts.ofText} {filteredReport.length}{' '}
                    {texts.entriesText}
                </p>
                <Pagination
                    onPageChange={(page) => setCurrentPage(page)}
                    totalCount={filteredReport.length}
                    currentPage={currentPage}
                    pageSize={entriesPerPage}
                    siblingCount={0}
                />
            </div>
        </>
    );
}
