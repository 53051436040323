import React, { useState, useRef } from 'react';
import {
    Row,
    Col,
    Spinner,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
} from 'reactstrap';
import { FaUsers } from 'react-icons/fa';
import { BsSmartwatch } from 'react-icons/bs';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import styles from '../styles.module.scss';

import { Plot } from '../../../components/plot';
import { InfoCard } from '../../../components/infoCard';
import { AverageCardContainer } from './averageCardContainer';
import { UsageReportContainer } from './usageReportContainer';
import { fetchCompanyReportData } from '../../../services/api/dashboard';

CondorDashboard.propTypes = {
    dashboardData: PropTypes.shape({
        number_of_active_devices: PropTypes.number.isRequired,
        number_of_active_patients: PropTypes.number.isRequired,
        number_of_customers: PropTypes.number.isRequired,
        number_of_active_customers: PropTypes.number.isRequired,
        average_new_patients: PropTypes.shape({
            daily: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
            weekly: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
            monthly: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
        }),
        average_new_patients_chart: PropTypes.shape({
            daily: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
            weekly: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
            monthly: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
        }),
        average_new_customers: PropTypes.shape({
            daily: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
            weekly: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
            monthly: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
        }),
        average_new_customers_chart: PropTypes.shape({
            daily: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
            weekly: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
            monthly: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
        }),
        average_visits: PropTypes.shape({
            daily: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
            weekly: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
            monthly: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
        }),
        average_visits_chart: PropTypes.shape({
            daily: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
            weekly: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
            monthly: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
        }),
        average_data_inputs: PropTypes.shape({
            daily: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
            weekly: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
            monthly: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
        }),
        average_data_inputs_chart: PropTypes.shape({
            daily: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
            weekly: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
            monthly: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
        }),
        average_new_subscriptions: PropTypes.shape({
            daily: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
            weekly: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
            monthly: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
                .isRequired,
        }),
        average_new_subscriptions_chart: PropTypes.shape({
            daily: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
            weekly: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
            monthly: PropTypes.shape({
                data: PropTypes.array.isRequired,
                layout: PropTypes.object.isRequired,
                config: PropTypes.object.isRequired,
            }),
        }),
        companies_by_plan_chart: PropTypes.shape({
            data: PropTypes.arrayOf(PropTypes.object).isRequired,
            layout: PropTypes.object.isRequired,
            config: PropTypes.object.isRequired,
        }),
        complete_subscription_report: PropTypes.arrayOf(
            PropTypes.shape({
                company_id: PropTypes.number.isRequired,
                company_name: PropTypes.string.isRequired,
                user_name: PropTypes.string.isRequired,
                user_id: PropTypes.number.isRequired,
                user_is_active: PropTypes.bool.isRequired,
                plan_name: PropTypes.string.isRequired,
                max_patients: PropTypes.number.isRequired,
                active_patients: PropTypes.number.isRequired,
                sub_id: PropTypes.number.isRequired,
                sub_is_active: PropTypes.bool,
                sub_is_suspended: PropTypes.bool.isRequired,
                billing_date: PropTypes.string,
                expires_at: PropTypes.string,
                last_visit: PropTypes.string,
                last_data_input: PropTypes.string,
            })
        ),
    }),
    pageData: PropTypes.object,
};

export function CondorDashboard({ dashboardData, pageData }) {
    const companyReportRef = useRef();

    const [report, setReport] = useState(
        dashboardData?.complete_subscription_report
    );
    const [companyReport, setCompanyReport] = useState(null);
    const [openCompanyReport, setOpenCompanyReport] = useState({
        companyId: null,
        companyName: '',
        open: false,
    });
    const [loading, setLoading] = useState(false);

    async function handleOpenCompanyReport(companyId, companyName) {
        if (companyId === openCompanyReport.companyId) {
            if (!openCompanyReport.open) {
                companyReportRef.current.scrollIntoView({ behavior: 'smooth' });
            }

            setOpenCompanyReport({
                companyId: companyId,
                companyName: companyName,
                open: !openCompanyReport.open,
            });

            return;
        }

        companyReportRef.current.scrollIntoView({
            behavior: 'smooth',
        });

        setLoading(true);

        setOpenCompanyReport({
            companyId: companyId,
            companyName: companyName,
            open: true,
        });

        const data = await fetchCompanyReportData(companyId);

        if (data) {
            setCompanyReport(data);
        }

        setLoading(false);
    }

    if (!dashboardData) {
        return null;
    }

    if (dashboardData.number_of_customers === 0) {
        toast.info(pageData.noCustomersText);
    }

    return (
        <>
            <Row className={styles.cardItems}>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={dashboardData.number_of_active_patients}
                        title={pageData.activePatientsText}
                        icon={FaUsers}
                        className="blueBackground"
                    />
                </Col>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={dashboardData.number_of_customers}
                        title={pageData.customersText}
                        icon={FaUsers}
                        className="purpleBackground"
                    />
                </Col>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={dashboardData.number_of_active_customers}
                        title={pageData.activeCustomersText}
                        icon={FaUsers}
                        className="greenBackground"
                    />
                </Col>
                <Col xs="12" md="3" lg="3" xl="3">
                    <InfoCard
                        value={dashboardData.number_of_active_devices}
                        title={pageData.activeDevicesText}
                        icon={BsSmartwatch}
                        className="graphiteBackground"
                    />
                </Col>
            </Row>

            <Row className={styles.cardsGroup}>
                <Col xs="12" md="6" lg="6" xl="6">
                    <AverageCardContainer
                        title={pageData.newPatientsText}
                        texts={{
                            dayText: pageData.dayText,
                            weekText: pageData.weekText,
                            monthText: pageData.monthText,
                            averageText: pageData.averageNewPatientsText,
                            byDayText: pageData.byDayText,
                            byWeekText: pageData.byWeekText,
                            byMonthText: pageData.byMonthText,
                        }}
                        data={{
                            charts: dashboardData.average_new_patients_chart,
                            dailyAverage:
                                dashboardData.average_new_patients.daily,
                            weeklyAverage:
                                dashboardData.average_new_patients.weekly,
                            monthlyAverage:
                                dashboardData.average_new_patients.monthly,
                        }}
                    />
                </Col>
                <Col xs="12" md="6" lg="6" xl="6">
                    <AverageCardContainer
                        title={pageData.newCustomersText}
                        texts={{
                            dayText: pageData.dayText,
                            weekText: pageData.weekText,
                            monthText: pageData.monthText,
                            averageText: pageData.averageNewCustomersText,
                            byDayText: pageData.byDayText,
                            byWeekText: pageData.byWeekText,
                            byMonthText: pageData.byMonthText,
                        }}
                        data={{
                            charts: dashboardData.average_new_customers_chart,
                            dailyAverage:
                                dashboardData.average_new_customers.daily,
                            weeklyAverage:
                                dashboardData.average_new_customers.weekly,
                            monthlyAverage:
                                dashboardData.average_new_customers.monthly,
                        }}
                    />
                </Col>
            </Row>

            <Row className={styles.cardsGroup}>
                <Col xs="12" md="6" lg="6" xl="6">
                    <AverageCardContainer
                        title={pageData.visitsText}
                        texts={{
                            dayText: pageData.dayText,
                            weekText: pageData.weekText,
                            monthText: pageData.monthText,
                            averageText: pageData.averageNewVisitsText,
                            byDayText: pageData.byDayText,
                            byWeekText: pageData.byWeekText,
                            byMonthText: pageData.byMonthText,
                        }}
                        data={{
                            charts: dashboardData.average_visits_chart,
                            dailyAverage: dashboardData.average_visits.daily,
                            weeklyAverage: dashboardData.average_visits.weekly,
                            monthlyAverage:
                                dashboardData.average_visits.monthly,
                        }}
                    />
                </Col>
                <Col xs="12" md="6" lg="6" xl="6">
                    <AverageCardContainer
                        title={pageData.dataInputText}
                        texts={{
                            dayText: pageData.dayText,
                            weekText: pageData.weekText,
                            monthText: pageData.monthText,
                            averageText: pageData.averageNewDataInputText,
                            byDayText: pageData.byDayText,
                            byWeekText: pageData.byWeekText,
                            byMonthText: pageData.byMonthText,
                        }}
                        data={{
                            charts: dashboardData.average_data_inputs_chart,
                            dailyAverage:
                                dashboardData.average_data_inputs.daily,
                            weeklyAverage:
                                dashboardData.average_data_inputs.weekly,
                            monthlyAverage:
                                dashboardData.average_data_inputs.monthly,
                        }}
                    />
                </Col>
            </Row>

            <Row className={styles.cardsGroup}>
                <Col xs="12" md="6" lg="6" xl="6">
                    <AverageCardContainer
                        title={pageData.newSubscriptionsText}
                        texts={{
                            dayText: pageData.dayText,
                            weekText: pageData.weekText,
                            monthText: pageData.monthText,
                            averageText: pageData.averageNewSubscriptionsText,
                            byDayText: pageData.byDayText,
                            byWeekText: pageData.byWeekText,
                            byMonthText: pageData.byMonthText,
                        }}
                        data={{
                            charts: dashboardData.average_new_subscriptions_chart,
                            dailyAverage:
                                dashboardData.average_new_subscriptions.daily,
                            weeklyAverage:
                                dashboardData.average_new_subscriptions.weekly,
                            monthlyAverage:
                                dashboardData.average_new_subscriptions.monthly,
                        }}
                    />
                </Col>
                <Col xs="12" md="6" lg="6" xl="6">
                    <Card className={styles.cardShadow}>
                        <CardHeader className={styles.cardHeader}>
                            <CardTitle className={styles.cardTitle}>
                                <h6>
                                    {pageData.companiesByPlanText.toUpperCase()}
                                </h6>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row className={`${styles.cardWrapperBodyContent}`}>
                                <Col xs="12" className={styles.chartsContainer}>
                                    <Plot
                                        data={
                                            dashboardData
                                                .companies_by_plan_chart.data
                                        }
                                        layout={
                                            dashboardData
                                                .companies_by_plan_chart.layout
                                        }
                                        config={
                                            dashboardData
                                                .companies_by_plan_chart.config
                                        }
                                        useResizeHandler={true}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xs="12" md="12" lg="12" xl="12">
                    <Card
                        className={`${styles.cardShadow} dashboardTableRowTour`}
                    >
                        <CardHeader className={styles.cardHeader}>
                            <CardTitle className={styles.cardTitle}>
                                <h6>
                                    {pageData.usageReportText.toUpperCase()}
                                </h6>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <UsageReportContainer
                                texts={{
                                    usageReportText: pageData.usageReportText,
                                    showText: pageData.showText,
                                    showingText: pageData.showingText,
                                    toText: pageData.toText,
                                    ofText: pageData.ofText,
                                    entriesText: pageData.entriesText,
                                    yesText: pageData.yesText,
                                    noText: pageData.noText,
                                    wrongFormatText: pageData.wrongFormatText,
                                }}
                                ths={pageData.ths}
                                filterThs={pageData.filterThs}
                                tds={pageData.tds}
                                report={report}
                                handleOpenCompanyReport={
                                    handleOpenCompanyReport
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <p ref={companyReportRef} style={{ display: 'block' }}></p>

            {openCompanyReport.open && (
                <>
                    {loading ? (
                        <>
                            <Spinner>Loading...</Spinner>
                        </>
                    ) : (
                        <>
                            <Row>
                                <Col xs="12" md="12" lg="12" xl="12">
                                    <h4>{openCompanyReport.companyName}</h4>
                                </Col>
                            </Row>

                            <Row className={styles.cardItems}>
                                <Col xs="12" md="4" lg="4" xl="4">
                                    <InfoCard
                                        value={
                                            companyReport.number_of_active_patients
                                        }
                                        title={pageData.activePatientsText}
                                        icon={FaUsers}
                                        className="green500Background"
                                    />
                                </Col>
                                <Col xs="12" md="4" lg="4" xl="4">
                                    <InfoCard
                                        value={
                                            companyReport.number_of_customers
                                        }
                                        title={pageData.customersText}
                                        icon={FaUsers}
                                        className="yellowBackground"
                                    />
                                </Col>
                                <Col xs="12" md="4" lg="4" xl="4">
                                    <InfoCard
                                        value={
                                            companyReport.number_of_active_customers
                                        }
                                        title={pageData.activeCustomersText}
                                        icon={FaUsers}
                                        className="tealBackground"
                                    />
                                </Col>
                            </Row>

                            <Row className={styles.cardsGroup}>
                                <Col xs="12" md="6" lg="6" xl="6">
                                    <AverageCardContainer
                                        title={pageData.newPatientsText}
                                        texts={{
                                            dayText: pageData.dayText,
                                            weekText: pageData.weekText,
                                            monthText: pageData.monthText,
                                            averageText:
                                                pageData.averageNewPatientsText,
                                            byDayText: pageData.byDayText,
                                            byWeekText: pageData.byWeekText,
                                            byMonthText: pageData.byMonthText,
                                        }}
                                        data={{
                                            charts: companyReport.average_new_patients_chart,
                                            dailyAverage:
                                                companyReport
                                                    .average_new_patients.daily,
                                            weeklyAverage:
                                                companyReport
                                                    .average_new_patients
                                                    .weekly,
                                            monthlyAverage:
                                                companyReport
                                                    .average_new_patients
                                                    .monthly,
                                        }}
                                    />
                                </Col>
                                <Col xs="12" md="6" lg="6" xl="6">
                                    <AverageCardContainer
                                        title={pageData.newCustomersText}
                                        texts={{
                                            dayText: pageData.dayText,
                                            weekText: pageData.weekText,
                                            monthText: pageData.monthText,
                                            averageText:
                                                pageData.averageNewCustomersText,
                                            byDayText: pageData.byDayText,
                                            byWeekText: pageData.byWeekText,
                                            byMonthText: pageData.byMonthText,
                                        }}
                                        data={{
                                            charts: companyReport.average_new_customers_chart,
                                            dailyAverage:
                                                companyReport
                                                    .average_new_customers
                                                    .daily,
                                            weeklyAverage:
                                                companyReport
                                                    .average_new_customers
                                                    .weekly,
                                            monthlyAverage:
                                                companyReport
                                                    .average_new_customers
                                                    .monthly,
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Row className={styles.cardsGroup}>
                                <Col xs="12" md="6" lg="6" xl="6">
                                    <AverageCardContainer
                                        title={pageData.visitsText}
                                        texts={{
                                            dayText: pageData.dayText,
                                            weekText: pageData.weekText,
                                            monthText: pageData.monthText,
                                            averageText:
                                                pageData.averageNewVisitsText,
                                            byDayText: pageData.byDayText,
                                            byWeekText: pageData.byWeekText,
                                            byMonthText: pageData.byMonthText,
                                        }}
                                        data={{
                                            charts: companyReport.average_visits_chart,
                                            dailyAverage:
                                                companyReport.average_visits
                                                    .daily,
                                            weeklyAverage:
                                                companyReport.average_visits
                                                    .weekly,
                                            monthlyAverage:
                                                companyReport.average_visits
                                                    .monthly,
                                        }}
                                    />
                                </Col>
                                <Col xs="12" md="6" lg="6" xl="6">
                                    <AverageCardContainer
                                        title={pageData.dataInputText}
                                        texts={{
                                            dayText: pageData.dayText,
                                            weekText: pageData.weekText,
                                            monthText: pageData.monthText,
                                            averageText:
                                                pageData.averageNewDataInputText,
                                            byDayText: pageData.byDayText,
                                            byWeekText: pageData.byWeekText,
                                            byMonthText: pageData.byMonthText,
                                        }}
                                        data={{
                                            charts: companyReport.average_data_inputs_chart,
                                            dailyAverage:
                                                companyReport
                                                    .average_data_inputs.daily,
                                            weeklyAverage:
                                                companyReport
                                                    .average_data_inputs.weekly,
                                            monthlyAverage:
                                                companyReport
                                                    .average_data_inputs
                                                    .monthly,
                                        }}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}
        </>
    );
}
